import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import axios from "axios";
import underScore from "underscore";
import Select from "react-select";
import Spinner from "../layout/Spinner";
import { Modal } from "react-bootstrap";
import IssuePostModal from "./modals/IssuePostModal";
import moment from "moment-timezone";
import IssueHistory from "../layout/IssueHistory";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import PriorityDetails from "./modals/PriorityDetails";
import SeverityDetails from "./modals/SeverityDetails";

function IssueDetail(props) {
  const [issueChanged, setIssueChanged] = useState(false);
  const [dataLoaded, setdataLoaded] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [priorityHelp, setPriorityHelp] = useState(false);
  const [severityHelp, setSeverityHelp] = useState(false);
  const { getTokenSilently, user, loading } = useAuth0();

  useEffect(() => {
    getIssue(props.match.params.issueID);
  }, []);

  const getIssue = async (issueID) => {
    if (issueID === "new") {
      props.issuesStore.issueDetail = props.issuesStore.newIssue;
      props.issuesStore.origIssueDetail = props.issuesStore.newIssue;
      setdataLoaded(true);
    } else {
      const token = await getTokenSilently();
      axios
        .all([
          axios({
            method: "get",
            url: `/api/issue/issue?id=${props.match.params.issueID}`,
            headers: {
              accepts: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
          axios({
            method: "get",
            url: "/api/settings/getall",
            headers: {
              accepts: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
        ])
        .then(
          axios.spread((issue, settings) => {
            props.issuesStore.issueDetail = issue.data;
            props.issuesStore.origIssueDetail = issue.data;
            props.issuesStore.settings = settings.data;
            setdataLoaded(true);
          })
        )
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const testIssueChanged = () => {
    const issueTest = underScore.isEqual(
      props.issuesStore.issueDetail,
      props.issuesStore.origIssueDetail
    );
    if (issueTest === true) {
      setIssueChanged(!issueTest);
    } else {
      setIssueChanged(!issueTest);
    }
  };

  const handleSimpleFormChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    props.issuesStore.handleSimpleFormChange(key, value);
    testIssueChanged();
  };

  const changeSelect = (event, key) => {
    props.issuesStore.handleSimpleFormChange(key, event);
    testIssueChanged();
  };

  const updateResolved = () => {
    props.issuesStore.handleSimpleFormChange("resolved", !props.issuesStore.issueDetail.resolved)
    testIssueChanged()
  }

  const changeSelect2 = (event, broadKey, narrowKey) => {
    props.issuesStore.handleSimpleFormChange2(broadKey, narrowKey, event);
    testIssueChanged();
  };

  const addNewLine = (broadKey, narrowKey) => {
    props.issuesStore.addNewLine(broadKey, narrowKey);
    testIssueChanged();
  };

  const addNewLine3 = (broadKey, midKey, narrowKey) => {
    props.issuesStore.addNewLine3(broadKey, midKey, narrowKey);
    testIssueChanged();
  };

  const confirmDeleteLine3 = (broadKey, midKey, narrowKey, index) => {
    if (window.confirm("Are you sure you want to delete this line?")) {
      deleteLine3(broadKey, midKey, narrowKey, index);
    }
  };

  const deleteLine3 = (broadKey, midKey, narrowKey, index) => {
    props.issuesStore.deleteLine3(broadKey, midKey, narrowKey, index);
    testIssueChanged();
  };

  const confirmDeleteLine = (broadKey, narrowKey, index) => {
    if (window.confirm("Are you sure you want to delete this line?")) {
      deleteLine(broadKey, narrowKey, index);
    }
  };

  const deleteLine = (broadKey, narrowKey, index) => {
    props.issuesStore.deleteLine(broadKey, narrowKey, index);
    testIssueChanged();
  };

  const stripHTML = (htmlString) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = htmlString;
    return tmp.textContent;
  };

  const editLine = (event, broadKey, narrowKey, index) => {
    const text = event.currentTarget.textContent;
    const subText = stripHTML(text);
    props.issuesStore.editLine(broadKey, narrowKey, index, subText);
    testIssueChanged();
  };

  const editLine3 = (event, broadKey, midKey, narrowKey, index) => {
    const text = event.currentTarget.textContent;
    const subText = stripHTML(text);
    props.issuesStore.editLine3(broadKey, midKey, narrowKey, index, subText);
    testIssueChanged();
  };

  const clearComment = () => {
    props.issuesStore.updateComment = "";
    props.issuesStore.archiveCheck = "";
  };

  const postIssue = async () => {
    setdataLoaded(false);
    let data = {
      updatedIssue: props.issuesStore.issueDetail,
      changeUser: {
        userName: user.name,
        userEmail: user.email,
      },
      comment: props.issuesStore.updateComment,
    };

    const token = await getTokenSilently();
    if (props.match.params.issueID === "new") {

      axios({
        method: "post",
        url: "/api/issue/add",
        json: true,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((issue) => {
          props.history.push(`/issue/${issue.data._id}`);
          window.location.reload();
        })
        .catch((error) => console.log(error));
    } else {

      axios({
        method: "post",
        url: "/api/issue/update",
        json: true,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((issue) => {
          getIssue(issue.data._id);
          clearComment();
        })
        .catch((error) => console.log(error));
    }
  };

  if (loading || !dataLoaded) {
    return <Spinner />;
  }

  const toggleModalOpen = (modalType) => {
    switch (modalType) {
      case "postModal":
        setPostModal(!postModal)
            break
      case "priorityDetail":
        setPriorityHelp(!priorityHelp)
        break
      case "severityDetail":
        setSeverityHelp(!severityHelp)
        break
      default:
        break
    }
  }

  return (
    <div id="riskDetailForm">
      <div>
        <div className="sticky">
          <h1 className="form-heading">Issue Detail Edit Form</h1>
          <nav className="navbar navbar-light">
            <div className="mr-auto">
              <p id="form-paragraph">
                Please edit the form fields below and click 'save' to record in
                database. All fields are required.
              </p>
            </div>
            <div className="ml-auto">
              <div className="btn-group">
                {issueChanged ? <button
                    className="btn btn-outline-primary"
                    onClick={() => toggleModalOpen("postModal")}
                >
                  Save
                </button> : null}
                <button
                  className="btn btn-outline-danger"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </nav>
        </div>
        <br />
        <Modal show={postModal} onHide={() => toggleModalOpen("postModal")}>
          <Modal.Body>
            <IssuePostModal />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={() => {
                postIssue();
                toggleModalOpen("postModal");
              }}
            >
              Update Issue
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                toggleModalOpen("postModal");
                clearComment();
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <form>
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                    name="description"
                    id="description"
                    rows="2"
                    value={props.issuesStore.issueDetail.description}
                    onChange={handleSimpleFormChange}
                    // onBlur={handleSimpleFormBlur}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-5">
                <div className="form-group">
                  <label htmlFor="project">Project</label>
                  <Select
                      value={props.issuesStore.issueDetail.project}
                      options={props.riskStore.settings.narrowRiskCategories}
                      onChange={(event) =>
                          changeSelect(event, "project")
                      }
                  />
                </div>
            </div>
            <div className="col-md-5">
              <div className="form-group">
                <label htmlFor="classification">
                  Classification
                </label>
                <Select
                    value={props.issuesStore.issueDetail.classification}
                    options={props.riskStore.settings.broadRiskCategories}
                    onChange={(event) =>
                        changeSelect(event, "classification")
                    }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="issueID">Issue Status & ID</label>

                {props.issuesStore.issueDetail.resolved === true ? (
                    <div
                        style={{
                          backgroundColor: "#00cd00",
                          width: "100%",
                          textAlign: "center",
                        }}
                    >
                      <h4>Resolved</h4>
                      <p style={{ fontSize: ".8rem" }}>
                        {props.issuesStore.issueDetail._id === undefined
                            ? "NEW"
                            : `${props.issuesStore.issueDetail._id}`}
                      </p>
                    </div>
                ) : (
                    <div
                        style={{
                          backgroundColor: "#ff0000",
                          width: "100%",
                          textAlign: "center",
                        }}
                    >
                      <h4>Active</h4>
                      <p style={{ fontSize: ".8rem" }}>
                        {props.issuesStore.issueDetail._id === undefined
                            ? "NEW"
                            : `${props.issuesStore.issueDetail._id}`}
                      </p>
                    </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-row">
              <div className="col-md-10">
                <div className="form-group">
                  <label htmlFor="responsibleOfficer">
                    Responsible Officer
                  </label>
                  <Select
                      value={props.issuesStore.issueDetail.responsibleOfficer}
                      options={props.riskStore.settings.originators}
                      onChange={(event) =>
                          changeSelect(event, "responsibleOfficer")
                      }
                  />
                </div>
            </div>
            {
              props.issuesStore.issueDetail._id === undefined
                ? null
                  : <div className="col-md-2 text-center pt-4">
                    <div className="form-group">
                      <button
                          className="btn btn-primary"
                          onClick={() => updateResolved()}
                          type="button"
                      >
                        <FontAwesomeIcon icon={faCheck} />{props.issuesStore.issueDetail.resolved ? " Mark Active" : " Mark Resolved"}
                      </button>
                    </div>
                  </div>
            }
          </div>
          <div className="form-row">
            <div className="col-md-10">
              <div className="form-group">
                <label htmlFor="originator">
                  Raised By
                </label>
                <Select
                    value={props.issuesStore.issueDetail.originator}
                    options={props.riskStore.settings.originators}
                    onChange={(event) =>
                        changeSelect(event, "originator")
                    }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="dateRaised">Date Raised</label>
                <input
                    type="date"
                    name="dateRaised"
                    id="dateRaised"
                    value={moment(props.issuesStore.issueDetail.dateRaised).format("YYYY-MM-DD")}
                    onChange={handleSimpleFormChange}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="priority">Priority</label>
                <Select
                    value={
                      props.issuesStore.issueDetail.priority
                    }
                    options={props.riskStore.settings.Priorities}
                    onChange={(event) =>
                        changeSelect(
                            event,
                            "priority"
                        )
                    }
                />
              </div>
              <button
                  type="button"
                  className="btn btn-outline-primary btn-sm more-info-button"
                  onClick={() => toggleModalOpen("priorityDetail")}
              >
                Guidance
              </button>
              <Modal
                  show={priorityHelp}
                  onHide={() => toggleModalOpen("priorityDetail")}
              >
                <Modal.Body>
                  <PriorityDetails />
                </Modal.Body>
                <Modal.Footer>
                  <button
                      className="btn btn-primary"
                      onClick={() => toggleModalOpen("priorityDetail")}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="priority">Severity</label>
                <Select
                    value={
                      props.issuesStore.issueDetail.severity
                    }
                    options={props.riskStore.settings.severities}
                    onChange={(event) =>
                        changeSelect(
                            event,
                            "severity"
                        )
                    }
                />
              </div>
              <button
                  type="button"
                  className="btn btn-outline-primary btn-sm more-info-button"
                  onClick={() => toggleModalOpen("severityDetail")}
              >
                Guidance
              </button>
              <Modal
                  show={severityHelp}
                  onHide={() => toggleModalOpen("severityDetail")}
              >
                <Modal.Body>
                  <SeverityDetails />
                </Modal.Body>
                <Modal.Footer>
                  <button
                      className="btn btn-primary"
                      onClick={() => toggleModalOpen("severityDetail")}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="comments">Comments</label>
                <textarea
                    name="comments"
                    id="comments"
                    rows="8"
                    value={props.issuesStore.issueDetail.comments}
                    onChange={handleSimpleFormChange}
                />
              </div>
            </div>
          </div>
        </form>
        <br />
        <IssueHistory />
        <br />
      </div>
    </div>
  );
}

export default inject("riskStore","issuesStore")(observer(IssueDetail));
