import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import axios from "axios";

import Spinner from "../layout/Spinner";

const Register = (props) => {
  const [dataLoaded, setdataLoaded] = useState(false);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = await getTokenSilently();

    axios({
      method: "get",
      url: "/api/risk/risklist",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((risks) => {
        props.riskStore.riskList = risks.data;
        console.log("Risks successfully loaded.");
        setdataLoaded(true);
        axios({
          method: "get",
          url: "/api/settings/getall",
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((settings) => {
            props.riskStore.settings = settings.data;
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/risk/${row._id}`);
    },
  };

  return dataLoaded ? (
    <div>
      <br />
      <h1 className="register-heading">Risk Register</h1>
      <div className="row">
        <div className="col-md-4">
          <p className="register-text">
            Please use the column headings to sort the register. To add a new
            task, click the button to the right.
          </p>
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-2">
          <button
            className="btn btn-primary"
            onClick={() => props.history.push("/risk/new")}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Risk
          </button>
        </div>
      </div>

      <BootstrapTable
        keyField="id"
        data={props.riskStore.riskList}
        columns={risksColumns}
        filter={filterFactory()}
        filterPosition="top"
        striped={true}
        hover={true}
        condensed={true}
        rowEvents={rowEvents}
        bootstrap4={true}
      />
    </div>
  ) : (
    <Spinner />
  );
};

const selectOptions = {
  true: "Archived",
  false: "Active",
};

const archiveFormatter = (cell, row, rowIndex, colIndex) => {
  if (cell === true) {
    return <span>Archived</span>;
  } else {
    return <span>Active</span>;
  }
};

const risksColumns = [
  {
    dataField: "_id",
    text: "",
    hidden: true,
  },
  {
    dataField: "riskName",
    text: "Risk",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { width: "31%" };
    },
  },
  {
    dataField: "riskCategoryBroad.value",
    text: "Broad Risk Category",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { textAlign: "center" };
    },
    align: "center",
  },
  {
    dataField: "riskCategoryNarrow.value",
    text: "Narrow Risk Category",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { textAlign: "center" };
    },
    align: "center",
  },
  {
    dataField: "riskOfficerPrimary.value",
    text: "Responsible Officer (Primary)",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { width: "20%", textAlign: "center" };
    },
    align: "center",
  },
  {
    dataField: "grossRiskAssessment.grossRiskRating.label",
    text: "Gross Risk Rating",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { textAlign: "center" };
    },
    align: "center",
  },
  {
    dataField: "netRiskAssessment.netRiskRating.label",
    text: "Net Risk Rating",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { textAlign: "center" };
    },
    align: "center",
  },
  {
    dataField: "archived",
    text: "Archived",
    sort: true,
    filter: selectFilter({ options: selectOptions }),
    headerStyle: (column, colIndex) => {
      return { textAlign: "center", width: "8%" };
    },
    formatter: archiveFormatter,
    align: "center",
  },
];

export default inject("riskStore")(observer(Register));
