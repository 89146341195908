import React, { useState } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import Axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusSquare} from "@fortawesome/free-solid-svg-icons";

function Severities(props) {
  const { getTokenSilently } = useAuth0();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [id, setId] = useState("");
  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");

  const saveChanges = async () => {
    setLoading(true);
    const token = await getTokenSilently();
    const url = index === 'new' ? "/api/settings/add" : "/api/settings/modify"
    const data = {
      type: "severities",
      _id: id,
      label,
      id,
      description,
    };
    Axios({
      method: "post",
      url: url,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((setting) => {
        if(index === "new") {
          props.riskStore.addNewSetting("severities", setting.data)
        } else {
          props.riskStore.settings.severities[index] = data;
        }
        toggleModalOpen();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const newSeverity = () => {
    setIndex("new")
    setId("")
    setLabel("")
    setShowModal(true)
    setChanged(false)
    setLoading(false)
  }

  const toggleModalOpen = () => {
    setShowModal(!showModal);
    setId("");
    setLabel("");
    setDescription("");
    setChanged(false);
  };

  const editRow = (index) => {
    setIndex(index);
    setId(props.riskStore.settings.severities[index].id);
    setLabel(props.riskStore.settings.severities[index].label);
    setDescription(props.riskStore.settings.severities[index].description);
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const deleteCategory = async () => {
    if (
        window.confirm(
            "Are you sure you want to delete this severity? This is irreversible."
        )
    ) {
      setLoading(true);
      const token = await getTokenSilently();
      const data = {
        _id: id,
      };
      Axios({
        method: "delete",
        url: "/api/settings/delete",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
          .then((setting) => {
            props.riskStore.deleteSetting("severities", index);
            toggleModalOpen();
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
    }
  };

  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "catName":
        setLabel(value)
        break
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
    if (!changed) {
      setChanged(true);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={() => toggleModalOpen()}>
        <Modal.Header>
          <h3>{index === 'new' ? "Add Severity" : "Edit Severity - " + label}</h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="catName">Severity Name</label>
                <input
                  type="text"
                  id="catName"
                  name="catName"
                  value={label}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="2"
                  value={description}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {loading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                {index !== "new" ? <button
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      deleteCategory();
                    }}
                >
                  Delete Severity
                </button> : null}
                {changed ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      saveChanges();
                    }}
                  >
                    Save
                  </button>
                ) : null}
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-sm-10">
          <h1>Issue Severity</h1>
          <p>
            Using the table below you are able to update the priority
            criteria. The descriptors in the table below are those that will be
            shown to staff to guide them when choosing an issue priority (in the
            issue detail screen).
          </p>
        </div>
        <div className="col-sm-2">
          <button
              style={{ float: "right" }}
              className="btn btn-primary"
              onClick={() => {
                newSeverity();
              }}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Severity
          </button>
        </div>
      </div>

      <br />
      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th style={{ width: "35%" }}>Name</th>
            <th style={{ width: "65%" }}>Description</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.severities.map(function (
            severity,
            index
          ) {
            return (
              <tr key={index} onDoubleClick={() => editRow(index)}>
                <td>{severity.label}</td>
                <td>{severity.description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </div>
  );
}

export default inject("riskStore")(observer(Severities));
