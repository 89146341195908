import React from "react";
import { inject, observer } from "mobx-react";

function ControllabilityDetails(props) {
  return (
    <div>
      <h1>Assessing the Controllability Ranking</h1>
      <p>
        The following three levels are used to rate the capacity of the
        Corporation to influence the risk:
      </p>
      <table className="table table-striped">
        <thead>
          <tr className="bg-primary instructHead">
            <th style={{ width: "30%" }}>Ranking</th>
            <th style={{ width: "70%" }}>Description</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.controllability.map(function (item, index) {
            return (
              <tr key={index}>
                <td className="instructCell">{item.label}</td>
                <td>{item.description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default inject("riskStore")(observer(ControllabilityDetails));
