import React from "react";
import { inject, observer } from "mobx-react";

function ArchiveModal(props) {
  const handleComment = (event) => {
    const text = event.target.value;
    props.riskStore.handleUpdateComment(text);
  };

  const handleArchive = (event) => {
    const text = event.target.value;
    props.riskStore.handleUpdateArchiveCheck(text);
  };

  return (
    <div>
      <h1>Are you sure?</h1>
      <p>Archiving a risk is a permanent operation...</p>
      <p>
        As this is potentially destructive, a comment is required to explain why
        this risk should be archived.
      </p>
      <div className="row">
        <div className="col-md-12">
          <textarea
            className="form-control form-control-large mb-3"
            name="updateComment"
            id="updateComment"
            rows="5"
            value={props.riskStore.updateComment}
            onChange={handleComment}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <textarea
            className="form-control form-control-large mb-3"
            name="archiveCheck"
            id="archiveCheck"
            rows="1"
            placeholder="Enter 'Archive' here to activate the archive button"
            value={props.riskStore.archiveCheck}
            onChange={handleArchive}
          />
        </div>
      </div>
    </div>
  );
}

export default inject("riskStore")(observer(ArchiveModal));
