import React, { useState, Fragment } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import "react-tabs/style/react-tabs.css";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileCsv,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import { saveAs } from "file-saver";
import Axios from "axios";
import Select from "react-select";
import DatePicker from "react-datepicker";

import Spinner from "../../layout/Spinner";

function IssueDetail(props) {
  const { getTokenSilently, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(true);
  const [serverError, setServerError] = useState("");
  const [changed, setChanged] = useState(false);
  const [noFilter, setNoFilter] = useState(true);
  const [projectFilter, setProjectFilter] = useState(false);
  const [classificationFilter, setClassificationFilter] = useState(false)
  const [officerFilter, setOfficerFilter] = useState(false);

  const [search, setSearch] = useState({
    s1_inUse: true,
    s1_field: "",
    s1_primSec: "",
    s1_operator: "OR",
    s1_value: "",
    s2_inUse: false,
    s2_field: "",
    s2_primSec: "",
    s2_operator: "OR",
    s2_value: "",
    s3_inUse: false,
    s3_field: "",
    s3_primSec: "",
    s3_operator: "OR",
    s3_value: "",
  });

  const searchFields = [
    { label: "Project", value: "Project" },
    { label: "Classification", value: "Classification" },
    { label: "Officer", value: "Officer" },
  ];

  const operators = [
    { label: "AND", value: "AND" },
    { label: "OR", value: "OR" },
  ];

  const primSec = [
    { label: "Raised By", value: "Raised By" },
    { label: "Responsible", value: "Responsible" },
  ];

  const toggleChange = (event) => {
    const value = event.target.checked;
    const name = event.target.name;
    switch (name) {
      case "noFilter":
        setNoFilter(value);
        break;
      case "projectFilter":
        setProjectFilter(value);
        if (changed) {
          setChanged(false);
        }
        break;
      case "classificationFilter":
        setClassificationFilter(value);
        if (changed) {
          setChanged(false);
        }
        break;
      case "officerFilter":
        setOfficerFilter(value);
        if (changed) {
          setChanged(false);
        }
        break;
      default:
        break;
    }
  };

  const removeCriteria = (event, key) => {
    event.preventDefault();
    switch (key) {
      case "s1":
        setSearch({
          ...search,
          s1_field: "",
          s1_value: "",
          s1_operator: "OR",
          s1_primSec: "",
        });
        break;
      case "s2":
        setSearch({
          ...search,
          s2_field: "",
          s2_value: "",
          s2_operator: "OR",
          s2_primSec: "",
          s2_inUse: false,
        });
        break;
      case "s3":
        setSearch({
          ...search,
          s3_field: "",
          s3_value: "",
          s3_operator: "OR",
          s3_primSec: "",
          s3_inUse: false,
        });
        break;
      default:
        break;
    }
  };

  const addCriteria = (event, key) => {
    event.preventDefault();
    switch (key) {
      case "s2":
        setSearch({
          ...search,
          s2_inUse: true,
        });
        break;
      case "s3":
        setSearch({
          ...search,
          s3_inUse: true,
        });
        break;
      default:
        break;
    }
  };

  const changeSelect = (event, key) => {
    const value = event.label;
    switch (key) {
      case "s1_field":
        setSearch({
          ...search,
          s1_field: value,
          s1_primSec: "",
          s1_value: "",
        });
        break;
      case "s1_operator":
        setSearch({ ...search, s1_operator: value });
        break;
      case "s1_value":
        setSearch({ ...search, s1_value: value });
        break;
      case "s1_primSec":
        setSearch({ ...search, s1_primSec: value, s1_value: "" });
        break;
      case "s2_field":
        setSearch({
          ...search,
          s2_field: value,
          s2_primSec: "",
          s2_value: "",
        });
        break;
      case "s2_operator":
        setSearch({ ...search, s2_operator: value });
        break;
      case "s2_value":
        setSearch({ ...search, s2_value: value });
        break;
      case "s2_primSec":
        setSearch({ ...search, s2_primSec: value, s2_value: "" });
        break;
      case "s3_field":
        setSearch({
          ...search,
          s3_field: value,
          s3_primSec: "",
          s3_value: "",
        });
        break;
      case "s3_operator":
        setSearch({ ...search, s3_operator: value });
        break;
      case "s3_value":
        setSearch({ ...search, s3_value: value });
        break;
      case "s3_primSec":
        setSearch({ ...search, s3_primSec: value, s3_value: "" });
        break;
      default:
        break;
    }
    if (!changed) {
      setChanged(true);
    }
  };

  const getIssueDetail = async (type) => {
    setDataLoaded(false);
    setServerError("");
    const payLoad = {
      noFilter,
      reportType: type,
      ...search,
    };

    const token = await getTokenSilently();

    Axios({
      method: "post",
      url: "/api/reports/issue/detail",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: payLoad,
    })
      .then((data) => {
        const payLoad2 = data.data;
        if (payLoad2.type === "CSV") {
          Axios({
            method: "post",
            url: "/api/reports/issue/detaildlcsv",
            headers: {
              accepts: "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: payLoad2,
            responseType: "blob",
          })
            .then((response) => {
              setDataLoaded(true);
              const reportBlob = new Blob([response.data], {
                type: "text/csv",
              });
              const dateNow = moment().format("DDmmYY-hhmmss");
              saveAs(
                reportBlob,
                `Issue Detail Report - ${dateNow.toString()}.csv`
              );
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (payLoad2.type === "PDF") {
          Axios({
            method: "post",
            url: "/api/reports/issue/detaildlpdf",
            headers: {
              accepts: "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: payLoad2,
            responseType: "blob",
          })
            .then((response) => {
              setDataLoaded(true);
              const reportBlob = new Blob([response.data], {
                type: "application/pdf",
              });
              const dateNow = moment().format("DDmmYY-hhmmss");
              saveAs(
                reportBlob,
                `Issue Detail Report - ${dateNow.toString()}.pdf`
              );
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setServerError(
          "Sorry... that combination of filters doesn't seem to yield any data."
        );
        setDataLoaded(true);
        console.log(error);
      });
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <h2>Issue Detail Report</h2>
          <p>
            The issue detail report allows users to export all issues from the
            system (to PDF or Excel), as well as filter the output on a number
            of different criteria.
          </p>
          <p>
            Please use the following fields to define the report that you wish
            to download. It is possible to define fields in the below which will
            return no results, therefore it is suggested to filter only on one
            criteria (eg, Date) at a time.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <form>
            <div className="form-row">
              <div className="col-md-6">
                <h5>Do not Filter - Return all Issues</h5>
              </div>
              <div className="col-md-6">
                <Toggle
                  defaultChecked={noFilter}
                  onChange={toggleChange}
                  name="noFilter"
                />
              </div>
            </div>
            {noFilter ? null : (
              <Fragment>
                {!search.s1_inUse ? null : (
                  <Fragment>
                    <div className="form-row">
                      <div className="col-md-2">
                        <label htmlFor="searchField1">Search Field</label>
                        <Select
                          value={search.s1_field}
                          placeholder={search.s1_field}
                          options={searchFields}
                          onChange={(event) => changeSelect(event, "s1_field")}
                        />
                      </div>
                      <div className="col-md-10">
                        {search.s1_field === "" ? (
                          <Fragment>
                            <label htmlFor="searchvalue1">Search Term</label>
                            <p>Select a search field</p>
                          </Fragment>
                        ) : null}
                        {search.s1_field === "Project" ? (
                          <Fragment>
                            <div className="form-row">
                              <div className="col-md-9">
                                <label htmlFor="searchvalue1">
                                  Project
                                </label>
                                <Select
                                  value={search.s1_value}
                                  placeholder={search.s1_value}
                                  options={props.riskStore.settings.narrowRiskCategories}
                                  onChange={(event) =>
                                  {
                                    changeSelect(event, "s1_primSec")
                                    changeSelect(event, "s1_value")
                                  }
                                  }
                                />
                              </div>
                              <div className="col-md-3">
                                <label htmlFor="searchvalue1">Operator</label>
                                <Select
                                  value={search.s2_operator}
                                  placeholder={search.s2_operator}
                                  options={operators}
                                  onChange={(event) =>
                                    changeSelect(event, "s2_operator")
                                  }
                                />
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                        {search.s1_field === "Classification" ? (
                            <Fragment>
                              <div className="form-row">
                                <div className="col-md-9">
                                  <label htmlFor="searchvalue1">
                                    Classification
                                  </label>
                                  <Select
                                      value={search.s1_value}
                                      placeholder={search.s1_value}
                                      options={props.riskStore.settings.broadRiskCategories}
                                      onChange={(event) =>
                                      {
                                        changeSelect(event, "s1_primSec")
                                        changeSelect(event, "s1_value")
                                      }
                                      }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="searchvalue1">Operator</label>
                                  <Select
                                      value={search.s2_operator}
                                      placeholder={search.s2_operator}
                                      options={operators}
                                      onChange={(event) =>
                                          changeSelect(event, "s2_operator")
                                      }
                                  />
                                </div>
                              </div>
                            </Fragment>
                        ) : null}
                        {search.s1_field === "Officer" ? (
                          <Fragment>
                            <div className="form-row">
                              <div className="col-md-3">
                                <label htmlFor="searchvalue1">
                                  Raised By or Responsible for
                                </label>
                                <Select
                                  value={search.s1_primSec}
                                  placeholder={search.s1_primSec}
                                  options={primSec}
                                  onChange={(event) =>
                                    changeSelect(event, "s1_primSec")
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="searchvalue1">Officer</label>
                                <Select
                                  value={search.s1_value}
                                  placeholder={search.s1_value}
                                  options={props.riskStore.settings.originators}
                                  onChange={(event) =>
                                    changeSelect(event, "s1_value")
                                  }
                                />
                              </div>
                              <div className="col-md-3">
                                <label htmlFor="searchvalue1">Operator</label>
                                <Select
                                  value={search.s2_operator}
                                  placeholder={search.s2_operator}
                                  options={operators}
                                  onChange={(event) =>
                                    changeSelect(event, "s2_operator")
                                  }
                                />
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </Fragment>
                )}
                {!search.s2_inUse ? null : (
                  <Fragment>
                    <div className="form-row">
                      <div className="col-md-2">
                        <label htmlFor="searchField1">Search Field</label>
                        <Select
                          value={search.s2_field}
                          placeholder={search.s2_field}
                          options={searchFields}
                          onChange={(event) => changeSelect(event, "s2_field")}
                        />
                      </div>
                      <div className="col-md-10">
                        {search.s2_field === "" ? (
                          <Fragment>
                            <label htmlFor="searchvalue1">Search Term</label>
                            <p>Select a search field</p>
                          </Fragment>
                        ) : null}
                        {search.s2_field === "Project" ? (
                            <Fragment>
                              <div className="form-row">
                                <div className="col-md-9">
                                  <label htmlFor="searchvalue1">
                                    Project
                                  </label>
                                  <Select
                                      value={search.s2_value}
                                      placeholder={search.s2_value}
                                      options={props.riskStore.settings.narrowRiskCategories}
                                      onChange={(event) =>
                                      {
                                        changeSelect(event, "s2_primSec")
                                        changeSelect(event, "s2_value")
                                      }
                                      }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="searchvalue1">Operator</label>
                                  <Select
                                      value={search.s2_operator}
                                      placeholder={search.s2_operator}
                                      options={operators}
                                      onChange={(event) =>
                                          changeSelect(event, "s2_operator")
                                      }
                                  />
                                </div>
                              </div>
                            </Fragment>
                        ) : null}
                        {search.s2_field === "Classification" ? (
                            <Fragment>
                              <div className="form-row">
                                <div className="col-md-9">
                                  <label htmlFor="searchvalue1">
                                    Classification
                                  </label>
                                  <Select
                                      value={search.s2_value}
                                      placeholder={search.s2_value}
                                      options={props.riskStore.settings.broadRiskCategories}
                                      onChange={(event) =>
                                      {
                                        changeSelect(event, "s2_primSec")
                                        changeSelect(event, "s2_value")
                                      }
                                      }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="searchvalue1">Operator</label>
                                  <Select
                                      value={search.s2_operator}
                                      placeholder={search.s2_operator}
                                      options={operators}
                                      onChange={(event) =>
                                          changeSelect(event, "s2_operator")
                                      }
                                  />
                                </div>
                              </div>
                            </Fragment>
                        ) : null}
                        {search.s2_field === "Officer" ? (
                          <Fragment>
                            <div className="form-row">
                              <div className="col-md-3">
                                <label htmlFor="searchvalue1">
                                  Raised By or Responsible for
                                </label>
                                <Select
                                  value={search.s2_primSec}
                                  placeholder={search.s2_primSec}
                                  options={primSec}
                                  onChange={(event) =>
                                    changeSelect(event, "s2_primSec")
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="searchvalue1">Officer</label>
                                <Select
                                  value={search.s2_value}
                                  placeholder={search.s2_value}
                                  options={props.riskStore.settings.originators}
                                  onChange={(event) =>
                                    changeSelect(event, "s2_value")
                                  }
                                />
                              </div>
                              <div className="col-md-3">
                                <label htmlFor="searchvalue1">Operator</label>
                                <Select
                                  value={search.s3_operator}
                                  placeholder={search.s3_operator}
                                  options={operators}
                                  onChange={(event) =>
                                    changeSelect(event, "s3_operator")
                                  }
                                />
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </Fragment>
                )}
                {!search.s3_inUse ? null : (
                  <Fragment>
                    <div className="form-row">
                      <div className="col-md-2">
                        <label htmlFor="searchField1">Search Field</label>
                        <Select
                          value={search.s3_field}
                          placeholder={search.s3_field}
                          options={searchFields}
                          onChange={(event) => changeSelect(event, "s3_field")}
                        />
                      </div>
                      <div className="col-md-10">
                        {search.s3_field === "" ? (
                          <Fragment>
                            <label htmlFor="searchvalue1">Search Term</label>
                            <p>Select a search field</p>
                          </Fragment>
                        ) : null}
                        {search.s3_field === "Project" ? (
                            <Fragment>
                              <div className="form-row">
                                <div className="col-md-9">
                                  <label htmlFor="searchvalue1">
                                    Project
                                  </label>
                                  <Select
                                      value={search.s3_value}
                                      placeholder={search.s3_value}
                                      options={props.riskStore.settings.narrowRiskCategories}
                                      onChange={(event) =>
                                      {
                                        changeSelect(event, "s3_primSec")
                                        changeSelect(event, "s3_value")
                                      }
                                      }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="searchvalue1">Operator</label>
                                  <Select
                                      value={search.s2_operator}
                                      placeholder={search.s2_operator}
                                      options={operators}
                                      onChange={(event) =>
                                          changeSelect(event, "s2_operator")
                                      }
                                  />
                                </div>
                              </div>
                            </Fragment>
                        ) : null}
                        {search.s3_field === "Classification" ? (
                            <Fragment>
                              <div className="form-row">
                                <div className="col-md-9">
                                  <label htmlFor="searchvalue1">
                                    Classification
                                  </label>
                                  <Select
                                      value={search.s3_value}
                                      placeholder={search.s3_value}
                                      options={props.riskStore.settings.broadRiskCategories}
                                      onChange={(event) =>
                                      {
                                        changeSelect(event, "s3_primSec")
                                        changeSelect(event, "s3_value")
                                      }
                                      }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="searchvalue1">Operator</label>
                                  <Select
                                      value={search.s2_operator}
                                      placeholder={search.s2_operator}
                                      options={operators}
                                      onChange={(event) =>
                                          changeSelect(event, "s2_operator")
                                      }
                                  />
                                </div>
                              </div>
                            </Fragment>
                        ) : null}
                        {search.s3_field === "Officer" ? (
                          <Fragment>
                            <div className="form-row">
                              <div className="col-md-3">
                                <label htmlFor="searchvalue1">
                                  Raised By or Responsible for
                                </label>
                                <Select
                                  value={search.s3_primSec}
                                  placeholder={search.s3_primSec}
                                  options={primSec}
                                  onChange={(event) =>
                                    changeSelect(event, "s3_primSec")
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="searchvalue1">Officer</label>
                                <Select
                                  value={search.s3_value}
                                  placeholder={search.s3_value}
                                  options={props.riskStore.settings.originators}
                                  onChange={(event) =>
                                    changeSelect(event, "s3_value")
                                  }
                                />
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </Fragment>
                )}
                <br />
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="btn-group-ind float-right">
                      {search.s2_inUse ? null : (
                        <Fragment>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={(event) => {
                              addCriteria(event, "s2");
                            }}
                          >
                            Add Criteria
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={(event) => {
                              removeCriteria(event, "s1");
                            }}
                          >
                            Remove Criteria
                          </button>
                        </Fragment>
                      )}
                      {search.s3_inUse ? (
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={(event) => {
                            removeCriteria(event, "s3");
                          }}
                        >
                          Remove Criteria
                        </button>
                      ) : (
                        <Fragment>
                          {!search.s2_inUse ? null : (
                            <Fragment>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={(event) => {
                                  addCriteria(event, "s3");
                                }}
                              >
                                Add Criteria
                              </button>
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={(event) => {
                                  removeCriteria(event, "s2");
                                }}
                              >
                                Remove Criteria
                              </button>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </form>
        </div>{" "}
      </div>
      <div className="row">
        <div className="col-sm-12">
          <br />
          <h4 style={{ textAlign: "center", color: "red" }}>{serverError}</h4>
        </div>
      </div>
      <div className="row">
        <br />
        <div className="col-sm-8"></div>
        <div className="col-sm-4">
          <div className="btn-group-ind float-right">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                getIssueDetail("PDF");
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} /> PDF Report
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                getIssueDetail("CSV");
              }}
            >
              <FontAwesomeIcon icon={faFileCsv} /> CSV Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject("riskStore")(observer(IssueDetail));
