import React, { useState, useEffect, Fragment } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import "react-tabs/style/react-tabs.css";
import moment from "moment-timezone";
import Axios from "axios";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenFancy,
  faFilePdf,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import ContentEditable from "react-contenteditable";
import Select from "react-select";

import Spinner from "../../layout/Spinner";

function RiskManagementReview(props) {
  const { getTokenSilently, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(true);
  const [serverError, setServerError] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [asAtDate, setAsAtDate] = useState(null);
  const [receivedData, setReceivedData] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [narrowCategory, setNarrowCategory] = useState("");
  const [refreshState, setRefreshState] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    value: "Gross Risk (ASC)",
    label: "Gross Risk (ASC)",
  });

  useEffect(() => {
    checkValid();
  }, [narrowCategory, asAtDate, showButton]);

  const dateChange = (event, key) => {
    switch (key) {
      case "startDate":
        setStartDate(event);
        break;
      case "asAtDate":
        setAsAtDate(event);
        break;
      default:
        break;
    }
  };

  const changeSelect = (event) => {
    const value = event;
    setNarrowCategory(value);
  };

  const checkValid = () => {
    if (
      narrowCategory !== "" &&
      asAtDate !== null &&
      startDate !== null &&
      startDate < asAtDate
    ) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const stripHTML = (htmlString) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = htmlString;
    return tmp.textContent;
  };

  const editRMRData = (event, index) => {
    const text = event.currentTarget.textContent;
    const subText = stripHTML(text);
    receivedData[index].riskManagementReview = subText;
    console.log(receivedData);
  };

  const editRAData = (event, index) => {
    receivedData[index].ratingAssessment = {
      text: event.text,
      color: event.color,
    };
    setRefreshState(!refreshState);
  };

  const ratingAssessmentOptions = [
    {
      label: "Decreased Risk",
      value: "Decreased Risk",
      text: "Decreased Risk",
      color: "#00cd00",
    },
    {
      label: "No Change",
      value: "No Change",
      text: "No Change",
      color: "#FFFFFF",
    },
    {
      label: "Increased Risk",
      value: "Increased Risk",
      text: "Increased Risk",
      color: "#ff0000",
    },
    {
      label: "New Risk",
      value: "New Risk",
      text: "New Risk",
      color: "#179ED1",
    },
  ];

  const reSortLIst = async (event) => {
    setSortOrder(event);
    switch (event.value) {
      case "Gross Risk (ASC)":
        await sortData(receivedData, "gross", "asc");
        break;
      case "Gross Risk (DSC)":
        await sortData(receivedData, "gross", "dsc");
        break;
      case "Net Risk (ASC)":
        await sortData(receivedData, "net", "asc");
        break;
      case "Net Risk (DSC)":
        await sortData(receivedData, "net", "dsc");
        break;
      case "Alphabetical (ASC)":
        await sortData(receivedData, "alpha", "asc");
        break;
      case "Alphabetical (DSC)":
        await sortData(receivedData, "alpha", "dsc");
        break;
      default:
        break;
    }
  };

  const sortOrderOptions = [
    {
      value: "Gross Risk (ASC)",
      label: "Gross Risk (ASC)",
    },
    {
      value: "Gross Risk (DSC)",
      label: "Gross Risk (DSC)",
    },
    {
      value: "Net Risk (ASC)",
      label: "Net Risk (ASC)",
    },
    {
      value: "Net Risk (DSC)",
      label: "Net Risk (DSC)",
    },
    {
      value: "Alphabetical (ASC)",
      label: "Alphabetical/Numeric (ASC)",
    },
    {
      value: "Alphabetical (DSC)",
      label: "Alphabetical/Numeric (DSC)",
    },
  ];
  const discardChanges = () => {
    setStartDate(null);
    setAsAtDate(null);
    setReceivedData([]);
  };

  const sortData = (array, sortTerm, order) => {
    return new Promise((resolve, reject) => {
      if(sortTerm === "alpha") {
        if (order === "asc") {
          array.sort((a, b) => {
            if(a.riskName.toLowerCase() < b.riskName.toLowerCase()) {
              return -1;
            } else if (a.riskName.toLowerCase() > b.riskName.toLowerCase()) {
              return 1;
            } else {
              return 0
            }
          });
        } else if (order === "dsc") {
          array.sort((a, b) => {
            if(a.riskName.toLowerCase() > b.riskName.toLowerCase()) {
              return -1;
            } else if (a.riskName.toLowerCase() < b.riskName.toLowerCase()) {
              return 1;
            } else {
              return 0
            }
          });
        }
      } else {
        if (sortTerm === "gross") {
          array.forEach((item) => {
            if (item.grossRiskAssessment.grossRiskRating.label === "Low") {
              item.sort = 1;
            } else if (item.grossRiskAssessment.grossRiskRating.label === "Mod") {
              item.sort = 2;
            } else if (
                item.grossRiskAssessment.grossRiskRating.label === "High"
            ) {
              item.sort = 3;
            } else {
              item.sort = 4;
            }
          });
        } else if (sortTerm === "net") {
          array.forEach((item) => {
            if (item.netRiskAssessment.netRiskRating.label === "Low") {
              item.sort = 1;
            } else if (item.netRiskAssessment.netRiskRating.label === "Mod") {
              item.sort = 2;
            } else if (item.netRiskAssessment.netRiskRating.label === "High") {
              item.sort = 3;
            } else {
              item.sort = 4;
            }
          });
        }

        if (order === "asc") {
          array.sort((a, b) => {
            return a.sort - b.sort;
          });
        } else {
          array.sort((a, b) => {
            return b.sort - a.sort;
          });
        }
      }
      setReceivedData(array);
      resolve();
    });
  };

  const fetchReport = async () => {
    setDataLoaded(false);
    setServerError("");
    const payLoad = {
      data: receivedData,
    };

    const token = await getTokenSilently();

    Axios({
      method: "post",
      url: "/api/reports/riskreviewfinal",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: payLoad,
    })
      .then((data) => {
        const payLoad2 = data.data;

        Axios({
          method: "post",
          url: "/api/reports/riskreviewpdf",
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: payLoad2,
          responseType: "blob",
        })
          .then((response) => {
            setDataLoaded(true);
            const reportBlob = new Blob([response.data], {
              type: "application/pdf",
            });
            const dateNow = moment().format("DDmmYY-hhmmss");
            saveAs(
              reportBlob,
              `Risk Management Review - ${dateNow.toString()}.pdf`
            );
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
        setServerError("Sorry! An error has occurred.");
        setDataLoaded(true);
      });
  };

  const fetchDraftData = async () => {
    setDataLoaded(false);
    setServerError("");
    const payLoad = {
      startDate,
      asAtDate,
      narrowCategory,
    };

    const token = await getTokenSilently();

    Axios({
      method: "post",
      url: "/api/reports/riskreview",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: payLoad,
    })
      .then(async (data) => {
        console.log(data.data);
        sortData(data.data, "gross", "asc").then(() => setDataLoaded(true));
      })
      .catch((error) => {
        console.log(error);
        setServerError("Sorry! An error has occurred.");
        setDataLoaded(true);
      });
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <h2>Risk Management Review</h2>
            <p>
              The risk management review produces a report that compares active
              risks across two dates, and allows for user added comments.
            </p>
            <p>
              After entering both a reference date & reporting date below, you
              will be presented with a draft version of the report which will
              allow for editing of comments against risks. The last saved
              comments will pre-populate the template.
            </p>
          </div>
        </div>
        {receivedData.length === 0 ? (
          <Fragment>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <h5 htmlFor="startDate">Reference Date</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <DatePicker
                      selected={startDate}
                      onChange={(event) => dateChange(event, "startDate")}
                      isClearable={true}
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date("01 March 2013")}
                      maxDate={new Date()}
                      id="startDate"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <h5 htmlFor="asAtDate">Reporting Date</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <DatePicker
                      selected={asAtDate}
                      onChange={(event) => dateChange(event, "asAtDate")}
                      isClearable={true}
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date("01 March 2013")}
                      maxDate={new Date()}
                      id="asAtDate"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h5>Narrow Risk Category</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Select
                      value={narrowCategory}
                      placeholder={narrowCategory}
                      options={props.riskStore.settings.narrowRiskCategories}
                      onChange={(event) => changeSelect(event)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h4 style={{ textAlign: "center", color: "red" }}>
                  {serverError}
                </h4>
                {startDate >= asAtDate ? (
                  <Fragment>
                    {startDate !== null ? (
                      <Fragment>
                        {asAtDate !== null ? (
                          <h4 style={{ textAlign: "center", color: "red" }}>
                            Reference Date must be earlier than Reporting Date
                          </h4>
                        ) : null}
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="btn-group-ind float-right">
                  {showButton ? (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        fetchDraftData();
                      }}
                    >
                      <FontAwesomeIcon icon={faPenFancy} /> Get Draft Report
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </Fragment>
        ) : null}
        {receivedData.length === 0 ? null : (
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  Below is a draft report with the data that will be included in
                  the PDF. You can update the comments by clicking in the table
                  cell; or sort the risks using the dropdown below.
                </p>
                <p>
                  Once you have completed your updates, click the 'get report'
                  button below to download a completed PDF.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2">
                <h5 style={{ verticalAlign: "middle" }}>Sort Order</h5>
              </div>
              <div className="col-md-4">
                <Select
                  value={sortOrder}
                  placeholder={sortOrder}
                  options={sortOrderOptions}
                  onChange={(event) => reSortLIst(event)}
                />
              </div>
            </div>
            <div className="row" style={{ paddingTop: "12px" }}>
              <div className="col-md-12">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr style={{ backgroundColor: "#179ED1", color: "White" }}>
                      <th style={{ width: "25%" }}>Risk</th>
                      <th style={{ width: "20%", textAlign: "center" }}>
                        Current Rating
                      </th>
                      <th style={{ width: "35%", textAlign: "center" }}>
                        Comments
                      </th>
                      <th style={{ width: "20%", textAlign: "center" }}>
                        Rating Assessment
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {receivedData.map(function (item, index) {
                      return (
                        <tr key={index}>
                          <td>{item.riskName}</td>
                          <td>
                            <img
                              src={`svg/${item.svgimg}`}
                              alt="SVG Image"
                              className="centImg"
                            />
                          </td>
                          <td style={{ wordBreak: "break-word" }}>
                            <ContentEditable
                              html={item.riskManagementReview}
                              onBlur={(event) => editRMRData(event, index)}
                            />
                          </td>
                          <td
                            style={{
                              backgroundColor: `${item.ratingAssessment.color}`,
                            }}
                          >
                            <Select
                              value={item.ratingAssessment.text}
                              placeholder={item.ratingAssessment.text}
                              options={ratingAssessmentOptions}
                              onChange={(event) => editRAData(event, index)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="btn-group-ind float-right">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      fetchReport();
                    }}
                  >
                    <FontAwesomeIcon icon={faFilePdf} /> Save & Get Report
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      discardChanges();
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} /> Discard Changes
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      <br />
      <br />
    </Fragment>
  );
}

export default inject("riskStore")(observer(RiskManagementReview));
