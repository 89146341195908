import React, { useState, useEffect, Fragment } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import "react-tabs/style/react-tabs.css";
import Axios from "axios";
import Spinner from "../../layout/Spinner";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BootstrapTable from "react-bootstrap-table-next";
import {
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";

function CopyTabs(props) {
  const { getTokenSilently, user, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(false);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [sourceProject, setSourceProject] = useState("");
  const [destinationProject, setDestinationProject] = useState("");
  const [riskCopyList, setRiskCopyList] = useState([]);
  const [finalRiskList, setFinalRiskList] = useState([]);
  const [postModal, setPostModal] = useState(false);

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const token = await getTokenSilently();

    Axios({
      method: "get",
      url: "/api/settings/getall",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((settings) => {
        props.riskStore.settings = settings.data;
        setDataLoaded(true);
      })
      .catch((error) => console.log(error));
  };

  const getRisks = async () => {
    const token = await getTokenSilently();

    Axios({
      method: "get",
      url: `/api/risk/projectrisk?id=${sourceProject}`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((risks) => {
        let tempRiskArray = [];
        risks.data.forEach((risk) => {
          tempRiskArray.push({
            ...risk,
            toCopy: false,
          });
        });
        tempRiskArray.sort((a, b) => {
          return a.riskName - b.riskName;
        });
        setRiskCopyList(tempRiskArray);
        setStep2(true);
      })
      .catch((error) => console.log(error));
  };

  const changeSelect = (event, key) => {
    switch (key) {
      case "sourceProject":
        setSourceProject(event.value);
        break;
      case "destinationProject":
        setDestinationProject(event);
        break;
      default:
        break;
    }
  };

  const selectedFormatter = (cell, row) => {
    if (row.toCopy === true) {
      return (
        <span>
          <FontAwesomeIcon icon={faCheckCircle} color={"green"} />
        </span>
      );
    } else {
      return (
        <span>
          <FontAwesomeIcon icon={faTimesCircle} color={"red"} />
        </span>
      );
    }
  };

  const sourceFormatter = (cell, row) => {
    return <span>{sourceProject}</span>;
  };

  const destinationFormatter = (cell, row) => {
    return <span>{row.riskCategoryNarrow.label}</span>;
  };

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      let tempRiskArray = riskCopyList.filter((risk) => {
        return risk._id !== row._id;
      });
      let tempRiskList = riskCopyList.filter((risk) => {
        return risk._id === row._id;
      });
      let tempRisk = tempRiskList[0];
      tempRisk.toCopy = !row.toCopy;
      tempRiskArray.splice(rowIndex, 0, tempRisk);
      setRiskCopyList(tempRiskArray);
    },
  };

  const risksColumns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
    {
      dataField: "toCopy",
      text: "Selected",
      formatter: selectedFormatter,
      align: "center",
      headerAlign: "center",
      headerStyle: (column, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "riskName",
      text: "Risk Name",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "70%" };
      },
    },
  ];

  const finalRisksColumns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
    {
      dataField: "riskName",
      text: "Risk Name",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "60%" };
      },
    },
    {
      dataField: "id",
      text: "Copying From",
      formatter: sourceFormatter,
      align: "center",
      headerAlign: "center",
      headerStyle: (column, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "id",
      text: "Copying To",
      formatter: destinationFormatter,
      align: "center",
      headerAlign: "center",
      headerStyle: (column, colIndex) => {
        return { width: "20%" };
      },
    },
  ];

  const copyRisksConfirm = async () => {
    let changeArray = [];
    riskCopyList.forEach((risk) => {
      console.log(risk);
      if (risk.toCopy === true) {
        const newRisk = {
          grossRiskAssessment: risk.grossRiskAssessment,
          netRiskAssessment: risk.netRiskAssessment,
          superceded: false,
          riskHistory: [],
          archived: false,
          riskName: risk.riskName,
          riskManagementReview: "",
          riskCategoryBroad: risk.riskCategoryBroad,
          riskCategoryNarrow: destinationProject,
          riskOfficerPrimary: risk.riskOfficerPrimary,
          riskOfficerSecondary: risk.riskOfficerSecondary,
        };

        changeArray.push(newRisk);
      }
    });

    console.log(changeArray);
    setFinalRiskList(changeArray);
    setPostModal(!postModal);
  };

  const clearComment = () => {
    props.riskStore.updateComment = "";
    props.riskStore.archiveCheck = "";
  };

  const postNewRisks = async () => {
    setDataLoaded(false);
    const token = await getTokenSilently();

    const data = {
      riskList: finalRiskList,
      changeUser: {
        userName: user.name,
        userEmail: user.email,
      },
      comment: props.riskStore.updateComment,
      copy: true,
    };

    Axios({
      method: "post",
      url: `/api/risk/add`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        console.log(response);
        clearInputs();
        clearComment();
        setDataLoaded(true);
        toast.success("Risk/s have been added to the destination project.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((error) => {
        console.log(error);
        setDataLoaded(true);
        toast.error("Something has gone wrong... perhaps try again?", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const clearInputs = () => {
    setStep1(true);
    setStep2(false);
    setSourceProject("");
    setDestinationProject("");
    setRiskCopyList([]);
    setFinalRiskList([]);
  };

  const handleComment = (event) => {
    const text = event.target.value;
    props.riskStore.handleUpdateComment(text);
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <div className={"container"}>
        <div className="row">
          <h1>Copy Risks to Project</h1>
        </div>
        <div className="row">
          <p>
            The copy risks function below will allow you to copy one, or more,
            risks from an existing Project (Narrow Category) to another Project.
            Simply fill out the form and save the changes at the end.
          </p>
          <i>
            Note: If you want to copy risks to a new project, then be sure to
            create the project first on the Risk >> Narrow Categories tab.
          </i>
        </div>
        <div className="row" style={{ marginTop: "12px" }}>
          <div className="col-10">
            <h2>Step 1 - Choose Source Project (Where to Copy from)</h2>
          </div>
          <div className="col-2">
            {/*<button*/}
            {/*  className="btn btn-primary-outline"*/}
            {/*  onClick={() => setStep1(!step1)}*/}
            {/*>*/}
            {/*  Expand/Collapse*/}
            {/*</button>*/}
          </div>
        </div>
        {step1 ? (
          <div className={"row"} style={{ marginTop: "12px" }}>
            <div className="col-12">
              <p>
                Select a Project (Narrow Category) from the list; and then click
                the 'Get Risks' button.
              </p>
              <div className="row">
                <div className="col-md-7">
                  <Select
                    value={sourceProject}
                    placeholder={sourceProject}
                    options={props.riskStore.settings.narrowRiskCategories}
                    onChange={(event) => changeSelect(event, "sourceProject")}
                  />
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-2">
                  {sourceProject !== "" ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => getRisks()}
                    >
                      Get Risks
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="row" style={{ marginTop: "24px" }}>
          <div className="col-10">
            <h2>Step 2 - Choose Risks to Copy</h2>
          </div>
          <div className="col-2">
            {/*<button*/}
            {/*  className="btn btn-primary-outline"*/}
            {/*  onClick={() => setStep2(!step2)}*/}
            {/*>*/}
            {/*  Expand/Collapse*/}
            {/*</button>*/}
          </div>
        </div>
        {step2 ? (
          <div className={"row"}>
            <div className="col-12">
              <p>
                Double click to select one or more risks to copy to another
                project.
              </p>
            </div>
            <div className="col-md-12">
              <BootstrapTable
                keyField="_id"
                data={riskCopyList}
                columns={risksColumns}
                striped={true}
                hover={true}
                condensed={true}
                rowEvents={rowEvents}
                bootstrap4={true}
              />
            </div>
          </div>
        ) : null}

        <div className="row" style={{ marginTop: "24px" }}>
          <div className="col-10">
            <h2>Step 3 - Choose Destination Project (Where to Copy to)</h2>
          </div>
          <div className="col-2">
            {/*<button*/}
            {/*  className="btn btn-primary-outline"*/}
            {/*  onClick={() => setStep3(!step3)}*/}
            {/*>*/}
            {/*  Expand/Collapse*/}
            {/*</button>*/}
          </div>
        </div>
        {step2 ? (
          <div className={"row"}>
            <div className="col-12">
              <p>
                Select a project (Narrow Category) from the list. If the project
                isn't in the list, then please create it using the Risk >>
                Narrow Categories tab.
              </p>
              <div className="row">
                <div className="col-md-7">
                  <Select
                    value={destinationProject}
                    placeholder={destinationProject}
                    options={props.riskStore.settings.narrowRiskCategories}
                    onChange={(event) =>
                      changeSelect(event, "destinationProject")
                    }
                  />
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-2">
                  {destinationProject !== "" ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => copyRisksConfirm()}
                    >
                      Copy Risks
                    </button>
                  ) : null}
                </div>
                <div className="col-md-2">
                  {sourceProject !== "" ? (
                    <button
                      className="btn btn-danger"
                      onClick={() => clearInputs()}
                    >
                      Cancel
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        show={postModal}
        onHide={() => {
          setPostModal(false);
          clearComment();
        }}
      >
        <Modal.Body>
          <div>
            <h1>Are you sure?</h1>
            <p>
              Please review the changes noted below and enter any comments you
              would like to record against the changes you have made. Once you
              are done, click 'Copy Risks' below.
            </p>
            <div className="col-md-12">
              <BootstrapTable
                keyField="riskName"
                data={finalRiskList}
                columns={finalRisksColumns}
                striped={true}
                hover={true}
                condensed={true}
                bootstrap4={true}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <textarea
                  className="form-control form-control-large mb-3"
                  name="updateComment"
                  id="updateComment"
                  rows="5"
                  value={props.riskStore.updateComment}
                  onChange={handleComment}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              postNewRisks();
              setPostModal(false);
            }}
          >
            Copy Risks
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              setPostModal(false);
              clearComment();
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default inject("riskStore")(observer(CopyTabs));
