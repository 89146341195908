import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="footerDiv">
        <footer>
          <div className="text-center">
            <i>Made with </i>💓
            <i>
              {" "}
              by{" "}
              <a
                href="https://apps.risen.solutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Risen Solutions Apps
              </a>
            </i>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
