import React, { useState, useEffect, Fragment } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import "react-tabs/style/react-tabs.css";
import moment from "moment-timezone";
import Axios from "axios";
import { saveAs } from "file-saver";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";

import Spinner from "../../layout/Spinner";

function RiskRatings(props) {
  const { getTokenSilently, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(true);
  const [serverError, setServerError] = useState("");
  const [changed, setChanged] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [rmrValid, setRMRValid] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [asAtDate, setAsAtDate] = useState(null);
  const today = new Date();
  const [narrowCategory, setNarrowCategory] = useState("");
  const [sortOrder, setSortOrder] = useState({
    value: "Do Not Sort",
    label: "Do Not Sort",
  });

  useEffect(() => {
    checkValid();
  }, [narrowCategory, asAtDate, showButton]);

  const getRiskRatings = async () => {
    setDataLoaded(false);
    setServerError("");
    const payLoad = {
      asAtDate,
      today,
      narrowCategory,
      sortOrder,
    };

    const token = await getTokenSilently();

    Axios({
      method: "post",
      url: "/api/reports/riskrating",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: payLoad,
    })
      .then((data) => {
        const payLoad2 = data.data;
        console.log(payLoad2);
        Axios({
          method: "post",
          url: "/api/reports/riskratingdl",
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: payLoad2,
          responseType: "blob",
        })
          .then((response) => {
            setDataLoaded(true);
            const reportBlob = new Blob([response.data], {
              type: "application/pdf",
            });
            const dateNow = moment().format("DDmmYY-hhmmss");
            saveAs(
              reportBlob,
              `Risk Rating Report - ${dateNow.toString()}.pdf`
            );
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        setServerError("Sorry, something went wrong! Please try again.");
        setDataLoaded(true);
        console.log(err);
      });
  };

  const sortOrderOptions = [
    {
      value: "Do Not Sort",
      label: "Do Not Sort",
    },
    {
      value: "Gross Risk (ASC)",
      label: "Gross Risk (ASC)",
    },
    {
      value: "Gross Risk (DSC)",
      label: "Gross Risk (DSC)",
    },
    {
      value: "Net Risk (ASC)",
      label: "Net Risk (ASC)",
    },
    {
      value: "Net Risk (DSC)",
      label: "Net Risk (DSC)",
    },
    {
      value: "Alphabetical (ASC)",
      label: "Alphabetical/Numeric (ASC)",
    },
    {
      value: "Alphabetical (DSC)",
      label: "Alphabetical/Numeric (DSC)",
    },
  ];

  const asAtDateChange = (event) => {
    setAsAtDate(event);
  };

  const changeSelect = (event) => {
    const value = event.label;
    setNarrowCategory(value);
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  const checkValid = () => {
    if (narrowCategory !== "" && asAtDate !== null) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <h2>Board Risk Ratings Report</h2>
          <p>
            The Board risk ratings report produces a graphical report that
            provides high-level detail on each risk. It only shows risks that
            were active on the date chosen.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <h5>Report as at Date</h5>
        </div>
        <div className="col-sm-8">
          <DatePicker
            selected={asAtDate}
            onChange={asAtDateChange}
            isClearable={true}
            dateFormat="dd/MM/yyyy"
            minDate={new Date("01 March 2013")}
            maxDate={new Date()}
            id="asAtDate"
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-2">
          <h5>Narrow Risk Category</h5>
        </div>
        <div className="col-sm-8">
          <Select
            value={narrowCategory}
            placeholder={narrowCategory}
            options={props.riskStore.settings.narrowRiskCategories}
            onChange={(event) => changeSelect(event)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <h5>Sort Order</h5>
        </div>
        <div className="col-sm-4">
          <Select
            value={sortOrder}
            placeholder={sortOrder}
            options={sortOrderOptions}
            onChange={(event) => {
              setSortOrder(event);
            }}
          />
        </div>
      </div>
      <div className="row">
        <br />
        <div className="col-md-12">
          <h4 style={{ textAlign: "center", color: "red" }}>{serverError}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="btn-group-ind float-right">
            {showButton ? (
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  getRiskRatings();
                }}
              >
                <FontAwesomeIcon icon={faFilePdf} /> Get Report
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default inject("riskStore")(observer(RiskRatings));
