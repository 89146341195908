import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import axios from "axios";

import Spinner from "../layout/Spinner";
import moment from "moment-timezone";

const IssuesRegister = (props) => {
  const [dataLoaded, setdataLoaded] = useState(false);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = await getTokenSilently();

    axios({
      method: "get",
      url: "/api/issue/all",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((issues) => {
        props.issuesStore.issueList = issues.data;
        console.log("Issuess successfully loaded.");
        setdataLoaded(true);
        axios({
          method: "get",
          url: "/api/settings/getall",
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((settings) => {
            props.riskStore.settings = settings.data;
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/issue/${row._id}`);
    },
  };

  return dataLoaded ? (
    <div>
      <br />
      <h1 className="register-heading">Issues Register</h1>
      <div className="row">
        <div className="col-md-4">
          <p className="register-text">
            Please use the column headings to sort the register. To add a new
            issue, click the button to the right.
          </p>
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-2">
          <button
            className="btn btn-primary"
            onClick={() => props.history.push("/issue/new")}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Issue
          </button>
        </div>
      </div>

      <BootstrapTable
        keyField="id"
        data={props.issuesStore.issueList}
        columns={issuesColumns}
        filter={filterFactory()}
        filterPosition="top"
        striped={true}
        hover={true}
        condensed={true}
        rowEvents={rowEvents}
        bootstrap4={true}
      />
    </div>
  ) : (
    <Spinner />
  );
};

const selectOptions = {
  true: "Archived",
  false: "Active",
};

const resolvedOptions = {
  true: "Yes",
  false: "No"
}

const archiveFormatter = (cell, row, rowIndex, colIndex) => {
  if (cell === true) {
    return <span>Archived</span>;
  } else {
    return <span>Active</span>;
  }
};

const resolvedFormatter = (cell, row, rowIndex, colIndex) => {
  if (cell === true) {
    return <div style={{backgroundColor: "#00cd00"}}><span>Yes</span></div>;
  } else {
    return <div style={{backgroundColor: "#ff0000"}}><span>No</span></div>;
  }
};

const dateFormatter = (cell, row, rowIndex, colIndex) => {
  if(!cell) return ""
  return `${moment(cell).format("DD-MM-YYYY")}`
}

const issuesColumns = [
  {
    dataField: "_id",
    text: "",
    hidden: true,
  },
  {
    dataField: "dateRaised",
    text: "Date Raised",
    sort: true,
    formatter: dateFormatter,
    align: "center",
    headerStyle: (column, colIndex) => {
      return { width: "10%", textAlign: "center"  };
    },
  },
  {
    dataField: "project.value",
    text: "Project",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { width: "10%", textAlign: "center" };
    },
    align: "center",
  },
  // {
  //   dataField: "originator",
  //   text: "Raised By",
  //   filter: textFilter(),
  //   sort: true,
  //   headerStyle: (column, colIndex) => {
  //     return { width: "20%", textAlign: "center" };
  //   },
  //   align: "center",
  // },
  {
    dataField: "description",
    text: "Description",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { width: "25%" };
    },
  },
  {
    dataField: "classification.value",
    text: "Classification",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { width: "15%", textAlign: "center" };
    },
    align: "center",
  },
  {
    dataField: "responsibleOfficer.value",
    text: "Responsible Officer",
    filter: textFilter(),
    sort: true,
    headerStyle: (column, colIndex) => {
      return { width: "20%", textAlign: "center" };
    },
    align: "center",
  },
  // {
  //   dataField: "priority.label",
  //   text: "Priority",
  //   filter: textFilter(),
  //   sort: true,
  //   headerStyle: (column, colIndex) => {
  //     return { textAlign: "center" };
  //   },
  //   align: "center",
  // },
  // {
  //   dataField: "severity.label",
  //   text: "Severity",
  //   filter: textFilter(),
  //   sort: true,
  //   headerStyle: (column, colIndex) => {
  //     return { textAlign: "center" };
  //   },
  //   align: "center",
  // },
  {
    dataField: "resolved",
    text: "Resolved",
    sort: true,
    filter: selectFilter({ options: resolvedOptions }),
    headerStyle: (column, colIndex) => {
      return { textAlign: "center", width: "8%" };
    },
    formatter: resolvedFormatter,
    align: "center",
  },
  // {
  //   dataField: "archived",
  //   text: "Archived",
  //   sort: true,
  //   filter: selectFilter({ options: selectOptions }),
  //   headerStyle: (column, colIndex) => {
  //     return { textAlign: "center", width: "8%" };
  //   },
  //   formatter: archiveFormatter,
  //   align: "center",
  // },
];

export default inject("riskStore","issuesStore")(observer(IssuesRegister));
