import { observable, decorate, runInAction } from "mobx";

class issuesStore {
  issueList = [];
  issueDetail = {};
  origIssueDetail = {};
  snapshot = {};
  updateComment = "";
  archiveCheck = "";
  settings = {};
  user = {};
  users = [];

  newIssue = {
    resolved: false,
    project: "",
    dateRaised: Date.now(),
    originator: "",
    description: "",
    classification: "",
    responsibleOfficer: "",
    priority: {
      label: "Low",
      value: "#00cd00",
    },
    severity: {
      label: "Low",
      value: "#00cd00",
    },
    comments: "",
    issueHistory: [],
    archived: false
  };

  updateSnapshot = (snapshot) => {
    if (snapshot.oldissue === undefined) {
      snapshot.oldIssue = this.newIssue;
    }
    runInAction(() => {
      this.snapshot = snapshot;
    });
  };

  handleUpdateComment = (value) => {
    runInAction(() => {
      this.updateComment = value;
    });
  };

  handleUpdateArchiveCheck = (value) => {
    runInAction(() => {
      this.archiveCheck = value;
    });
  };

  handleSimpleFormChange = (key, value) => {
    runInAction(() => {
      this.issueDetail[key] = value;
    });
  };

  handleSimpleFormChange2 = (broadKey, narrowKey, value) => {
    runInAction(() => {
      this.issueDetail[broadKey][narrowKey] = value;
    });
  };

  handleTypeAhead = (value, key) => {
    runInAction(() => {
      this.issueDetail[key] = value;
    });
  };

  handleTypeAhead2Key = (value, broadKey, narrowKey) => {
    runInAction(() => {
      this.issueDetail[broadKey][narrowKey] = value;
    });
  };

  addNewLine = (broadKey, narrowKey) => {
    runInAction(() => {
      const tempIndex = this.issueDetail[broadKey][narrowKey].length + 1;
      this.issueDetail[broadKey][narrowKey].push({
        index: tempIndex,
        detail: "",
      });
    });
  };

  addNewLine3 = (broadKey, midKey, narrowKey) => {
    runInAction(() => {
      const tempIndex = this.issueDetail[broadKey][midKey][narrowKey].length + 1;
      this.issueDetail[broadKey][midKey][narrowKey].push({
        index: tempIndex,
        detail: "",
      });
    });
  };

  editLine = (broadKey, narrowKey, index, content) => {
    runInAction(() => {
      this.issueDetail[broadKey][narrowKey][index].detail = content;
    });
  };

  editLine3 = (broadKey, midKey, narrowKey, index, content) => {
    runInAction(() => {
      this.issueDetail[broadKey][midKey][narrowKey][index].detail = content;
    });
  };

  deleteLine = (broadKey, narrowKey, index) => {
    runInAction(() => {
      // const arrayIndex = this.riskDetail[broadKey][narrowKey].findIndex(
      //   (item) => item.id === id
      // );
      this.issueDetail[broadKey][narrowKey].splice(index, 1);
    });
  };

  deleteLine3 = (broadKey, midKey, narrowKey, index) => {
    runInAction(() => {
      this.issueDetail[broadKey][midKey][narrowKey].splice(index, 1);
    });
  };
}

decorate(issuesStore, {
  issueList: observable,
  issueDetail: observable,
  snapshot: observable,
  updateComment: observable,
  archiveCheck: observable,
  user: observable,
  users: observable,
});

export default new issuesStore();
