import React from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import moment from "moment-timezone";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  dateFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import { compose } from "recompose";

const IssueHistory = (props) => {
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/issuehistory/${row.snapshotID}`);
    },
  };

  const dateFormatter = (cell, row) => {
    return (
      <span>
        {moment(cell).tz("Australia/Hobart").format("DD MMMM YYYY h:mm a")}
      </span>
    );
  };

  const historyColumns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      filter: dateFilter(),
      headerStyle: (column, colIndex) => {
        return { width: "25%" };
      },
      formatter: dateFormatter,
    },
    {
      dataField: "changeUser.userName",
      text: "Name",
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "changeUser.userEmail",
      text: "Email",
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "snapshotID",
      text: "Commit ID",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "35%" };
      },
    },
  ];

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h5 className="form-heading">Issue History</h5>
        </div>
      </div>
      <br />
      <div className="historyTableDiv">
        <div className="row">
          <div className="col-md-12">
            <p>
              Below is a list of ALL the change events for this issue. Use the
              fields to filter, and click a row to view the detailed changes
              that were made.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <BootstrapTable
              keyField="snapshotID"
              data={props.issuesStore.issueDetail.issueHistory}
              columns={historyColumns}
              filter={filterFactory()}
              filterPosition="top"
              striped={true}
              hover={true}
              condensed={true}
              rowEvents={rowEvents}
              bootstrap4={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, inject("issuesStore"))(observer(IssueHistory));
