import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import axios from "axios";
import underScore from "underscore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ContentEditable from "react-contenteditable";
import Select from "react-select";
import RiskHistory from "../layout/RiskHistory";
import Spinner from "../layout/Spinner";
import { Modal } from "react-bootstrap";
import LikelyDetails from "./modals/LikelyDetails";
import ConseqDetails from "./modals/conseqDetails";
import ControllabilityDetails from "./modals/ControllabilityDetails";
import ControlEffectivenessDetails from "./modals/ControlEffectivenessDetails";
import PostModal from "./modals/PostModal";
import ArchiveModal from "./modals/ArchiveModal";
import UnArchiveModal from "./modals/UnArchiveModal";

function RiskDetail(props) {
  const [riskChanged, setriskChanged] = useState(false);
  const [dataLoaded, setdataLoaded] = useState(false);
  const [likelyDetail, setLikelyDetail] = useState(false);
  const [conseqDetail, setConseqDetail] = useState(false);
  const [controlAbilityDetail, setControlAbilityDetail] = useState(false);
  const [controlEffectivenessDetail, setControlEffectivenessDetail] = useState(
    false
  );
  const [postModal, setPostModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const { getTokenSilently, user, loading } = useAuth0();

  useEffect(() => {
    getRisk(props.match.params.riskID);
  }, []);

  const getRisk = async (riskID) => {
    if (riskID === "new") {
      props.riskStore.riskDetail = props.riskStore.newRisk;
      props.riskStore.origRiskDetail = props.riskStore.newRisk;
      console.log("Risk successfully loaded.");
      setdataLoaded(true);
    } else {
      const token = await getTokenSilently();
      axios
        .all([
          axios({
            method: "get",
            url: `/api/risk/risk?id=${props.match.params.riskID}`,
            headers: {
              accepts: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
          axios({
            method: "get",
            url: "/api/settings/getall",
            headers: {
              accepts: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
        ])
        .then(
          axios.spread((risk, settings) => {
            props.riskStore.riskDetail = risk.data;
            props.riskStore.origRiskDetail = risk.data;
            props.riskStore.settings = settings.data;
            setdataLoaded(true);
          })
        )
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const testRiskChanged = () => {
    const riskTest = underScore.isEqual(
      props.riskStore.riskDetail,
      props.riskStore.origRiskDetail
    );
    if (riskTest === true) {
      setriskChanged(!riskTest);
    } else {
      setriskChanged(!riskTest);
    }
  };

  const toggleModalOpen = (modalType) => {
    switch (modalType) {
      case "likelyDetail":
        setLikelyDetail(!likelyDetail);
        break;
      case "conseqDetail":
        setConseqDetail(!conseqDetail);
        break;
      case "controlAbilityDetail":
        setControlAbilityDetail(!controlAbilityDetail);
        break;
      case "controlEffectivenessDetail":
        setControlEffectivenessDetail(!controlEffectivenessDetail);
        break;
      case "postModal":
        setPostModal(!postModal);
        break;
      case "archiveModal":
        setArchiveModal(!archiveModal);
        break;
      default:
        break;
    }
  };

  const handleSimpleFormChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    props.riskStore.handleSimpleFormChange(key, value);
    testRiskChanged();
  };

  const changeSelect = (event, key) => {
    props.riskStore.handleSimpleFormChange(key, event);
    testRiskChanged();
  };

  const changeSelect2 = (event, broadKey, narrowKey) => {
    props.riskStore.handleSimpleFormChange2(broadKey, narrowKey, event);
    testRiskChanged();
  };

  const addNewLine = (broadKey, narrowKey) => {
    props.riskStore.addNewLine(broadKey, narrowKey);
    testRiskChanged();
  };

  const addNewLine3 = (broadKey, midKey, narrowKey) => {
    props.riskStore.addNewLine3(broadKey, midKey, narrowKey);
    testRiskChanged();
  };

  const confirmDeleteLine3 = (broadKey, midKey, narrowKey, index) => {
    if (window.confirm("Are you sure you want to delete this line?")) {
      deleteLine3(broadKey, midKey, narrowKey, index);
    }
  };

  const deleteLine3 = (broadKey, midKey, narrowKey, index) => {
    props.riskStore.deleteLine3(broadKey, midKey, narrowKey, index);
    testRiskChanged();
  };

  const confirmDeleteLine = (broadKey, narrowKey, index) => {
    if (window.confirm("Are you sure you want to delete this line?")) {
      deleteLine(broadKey, narrowKey, index);
    }
  };

  const deleteLine = (broadKey, narrowKey, index) => {
    props.riskStore.deleteLine(broadKey, narrowKey, index);
    testRiskChanged();
  };

  const stripHTML = (htmlString) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = htmlString;
    return tmp.textContent;
  };

  const editLine = (event, broadKey, narrowKey, index) => {
    const text = event.currentTarget.textContent;
    const subText = stripHTML(text);
    props.riskStore.editLine(broadKey, narrowKey, index, subText);
    testRiskChanged();
  };

  const editLine3 = (event, broadKey, midKey, narrowKey, index) => {
    const text = event.currentTarget.textContent;
    const subText = stripHTML(text);
    props.riskStore.editLine3(broadKey, midKey, narrowKey, index, subText);
    testRiskChanged();
  };

  const clearComment = () => {
    props.riskStore.updateComment = "";
    props.riskStore.archiveCheck = "";
  };

  const archiveRisk = async () => {
    setdataLoaded(false);
    props.riskStore.riskDetail.archived = true;
    let data = {
      updatedRisk: props.riskStore.riskDetail,
      changeUser: {
        userName: user.name,
        userEmail: user.email,
      },
      comment: props.riskStore.updateComment,
    };
    const token = await getTokenSilently();
    axios({
      method: "post",
      url: "/api/risk/archive",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then(() => {
        getRisk();
        clearComment();
      })
      .catch((error) => console.log(error));
  };

  const unArchiveRisk = async () => {
    setdataLoaded(false);
    props.riskStore.riskDetail.archived = false;
    let data = {
      updatedRisk: props.riskStore.riskDetail,
      changeUser: {
        userName: user.name,
        userEmail: user.email,
      },
      comment: props.riskStore.updateComment,
    };
    const token = await getTokenSilently();
    axios({
      method: "post",
      url: "/api/risk/unarchive",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
        .then(() => {
          getRisk();
          clearComment();
        })
        .catch((error) => console.log(error));
  };

  const postRisk = async () => {
    setdataLoaded(false);
    let data = {
      updatedRisk: props.riskStore.riskDetail,
      changeUser: {
        userName: user.name,
        userEmail: user.email,
      },
      comment: props.riskStore.updateComment,
    };
    const token = await getTokenSilently();
    if (props.match.params.riskID === "new") {
      console.log("new risk");
      axios({
        method: "post",
        url: "/api/risk/add",
        json: true,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((risk) => {
          props.history.push(`/risk/${risk.data._id}`);
          window.location.reload();
        })
        .catch((error) => console.log(error));
    } else {
      console.log("update risk");
      axios({
        method: "post",
        url: "/api/risk/update",
        json: true,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((risk) => {
          getRisk(risk.data._id);
          clearComment();
        })
        .catch((error) => console.log(error));
    }
  };

  if (loading || !dataLoaded) {
    return <Spinner />;
  }

  return (
    <div id="riskDetailForm">
      <div>
        <div className="sticky">
          <h1 className="form-heading">Risk Detail Edit Form</h1>
          <nav className="navbar navbar-light">
            <div className="mr-auto">
              <p id="form-paragraph">
                Please edit the form fields below and click 'save' to record in
                database. All fields are required.
              </p>
            </div>
            <div className="ml-auto">
              <div className="btn-group">
                {riskChanged ? (
                  props.riskStore.riskDetail.archived ? null : (
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => toggleModalOpen("postModal")}
                    >
                      Save
                    </button>
                  )
                ) : null}
                <button
                  className="btn btn-outline-danger"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </nav>
        </div>
        <br />
        <Modal show={postModal} onHide={() => toggleModalOpen("postModal")}>
          <Modal.Body>
            <PostModal />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={() => {
                postRisk();
                toggleModalOpen("postModal");
              }}
            >
              Update Risk
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                toggleModalOpen("postModal");
                clearComment();
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <form>
          <div className="form-row">
            <div className="col-md-10">
              <div className="form-group">
                <label htmlFor="riskName">Risk Name</label>
                <textarea
                  name="riskName"
                  id="riskName"
                  rows="2"
                  value={props.riskStore.riskDetail.riskName}
                  onChange={handleSimpleFormChange}
                  // onBlur={handleSimpleFormBlur}
                />
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="riskID">Risk Status & ID</label>

              {props.riskStore.riskDetail.archived === true ? (
                <div
                  style={{
                    backgroundColor: "#ff0000",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <h4>Archived</h4>
                  <p style={{ fontSize: ".8rem" }}>
                    {props.riskStore.riskDetail._id === undefined
                      ? "NEW"
                      : `${props.riskStore.riskDetail._id}`}
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "#00cd00",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <h4>Active</h4>
                  <p style={{ fontSize: ".8rem" }}>
                    {props.riskStore.riskDetail._id === undefined
                      ? "NEW"
                      : `${props.riskStore.riskDetail._id}`}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="broadRiskCategory">Broad Risk Category</label>
                <Select
                  value={props.riskStore.riskDetail.riskCategoryBroad}
                  options={props.riskStore.settings.broadRiskCategories}
                  onChange={(event) => changeSelect(event, "riskCategoryBroad")}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="narrowRiskCategory">Narrow Risk Category</label>
                <Select
                  value={props.riskStore.riskDetail.riskCategoryNarrow}
                  options={props.riskStore.settings.narrowRiskCategories}
                  onChange={(event) =>
                    changeSelect(event, "riskCategoryNarrow")
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="riskOfficerPrimary">
                  Responsible Officer (Primary)
                </label>
                <Select
                  value={props.riskStore.riskDetail.riskOfficerPrimary}
                  options={props.riskStore.settings.roles}
                  onChange={(event) =>
                    changeSelect(event, "riskOfficerPrimary")
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="riskOfficerSecondary">
                  Responsible Officer (Secondary)
                </label>
                <Select
                  value={props.riskStore.riskDetail.riskOfficerSecondary}
                  options={props.riskStore.settings.roles}
                  onChange={(event) =>
                    changeSelect(event, "riskOfficerSecondary")
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <h5 className="form-heading">Gross Risk Assessment</h5>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="potentialConsequences">Potential Causes</label>
                <table className="hoverTable table-responsive">
                  <tbody>
                    {props.riskStore.riskDetail.grossRiskAssessment.potentialCauses.map(
                      function (item, index) {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                "word-break": "break-word",
                                width: "90%",
                              }}
                            >
                              <ContentEditable
                                html={item.detail}
                                onBlur={(event) =>
                                  editLine(
                                    event,
                                    "grossRiskAssessment",
                                    "potentialCauses",
                                    index
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  confirmDeleteLine(
                                    "grossRiskAssessment",
                                    "potentialCauses",
                                    index
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    addNewLine("grossRiskAssessment", "potentialCauses");
                  }}
                >
                  Add New Line
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="potentialConsequences">
                  Potential Consequences
                </label>
                <table className="hoverTable">
                  <tbody>
                    {props.riskStore.riskDetail.grossRiskAssessment.potentialConsequences.map(
                      function (consequence, index) {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                "word-break": "break-word",
                                width: "90%",
                              }}
                            >
                              <ContentEditable
                                html={consequence.detail}
                                onBlur={(event) =>
                                  editLine(
                                    event,
                                    "grossRiskAssessment",
                                    "potentialConsequences",
                                    index
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  confirmDeleteLine(
                                    "grossRiskAssessment",
                                    "potentialConsequences",
                                    index
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    addNewLine("grossRiskAssessment", "potentialConsequences");
                  }}
                >
                  Add New Line
                </button>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="grossConsequence">Gross Consequence</label>
                <Select
                  value={
                    props.riskStore.riskDetail.grossRiskAssessment
                      .grossConsequence
                  }
                  options={props.riskStore.settings.riskConsequences}
                  onChange={(event) =>
                    changeSelect2(
                      event,
                      "grossRiskAssessment",
                      "grossConsequence"
                    )
                  }
                />
              </div>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm more-info-button"
                onClick={() => toggleModalOpen("conseqDetail")}
              >
                Guidance
              </button>
              <Modal
                show={conseqDetail}
                onHide={() => toggleModalOpen("conseqDetail")}
              >
                <Modal.Body>
                  <ConseqDetails />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-primary"
                    onClick={() => toggleModalOpen("conseqDetail")}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="grossLikelihood">Gross Likelihood</label>
                <Select
                  value={
                    props.riskStore.riskDetail.grossRiskAssessment
                      .grossLikelihood
                  }
                  options={props.riskStore.settings.riskLikelihoods}
                  onChange={(event) =>
                    changeSelect2(
                      event,
                      "grossRiskAssessment",
                      "grossLikelihood"
                    )
                  }
                />
              </div>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm more-info-button"
                onClick={() => toggleModalOpen("likelyDetail")}
              >
                Guidance
              </button>
              <Modal
                show={likelyDetail}
                onHide={() => toggleModalOpen("likelyDetail")}
              >
                <Modal.Body>
                  <LikelyDetails />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-primary"
                    onClick={() => toggleModalOpen("likelyDetail")}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="grossRiskRating">Gross Risk Rating</label>
              </div>

              <div
                className="ratingBox"
                style={{
                  backgroundColor: `${props.riskStore.riskDetail.grossRiskAssessment.grossRiskRating.value}`,
                }}
              >
                <h4>
                  {
                    props.riskStore.riskDetail.grossRiskAssessment
                      .grossRiskRating.label
                  }
                </h4>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <h5 className="form-heading">Net Risk Assessment</h5>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <h5 className="form-subheading">
                External Environment Considerations
              </h5>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="externalEnvironmentConsiderations">
                  External Environment Considerations
                </label>
                <table className="hoverTable">
                  <tbody>
                    {props.riskStore.riskDetail.netRiskAssessment.externalEnvironmentConsiderations.map(
                      function (item, index) {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                "word-break": "break-word",
                                width: "90%",
                              }}
                            >
                              <ContentEditable
                                html={item.detail}
                                onBlur={(event) =>
                                  editLine(
                                    event,
                                    "netRiskAssessment",
                                    "externalEnvironmentConsiderations",
                                    index
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  confirmDeleteLine(
                                    "netRiskAssessment",
                                    "externalEnvironmentConsiderations",
                                    index
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    addNewLine(
                      "netRiskAssessment",
                      "externalEnvironmentConsiderations"
                    );
                  }}
                >
                  Add New Line
                </button>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <h5 className="form-subheading">
                Internal Control Environment Considerations
              </h5>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="currentEnvironment">Current Environment</label>
                <table className="hoverTable">
                  <tbody>
                    {props.riskStore.riskDetail.netRiskAssessment.internalEnvironmentConsideration.currentEnvironment.map(
                      function (item, index) {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                "word-break": "break-word",
                                width: "90%",
                              }}
                            >
                              <ContentEditable
                                html={item.detail}
                                onBlur={(event) =>
                                  editLine3(
                                    event,
                                    "netRiskAssessment",
                                    "internalEnvironmentConsideration",
                                    "currentEnvironment",
                                    index
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  confirmDeleteLine3(
                                    "netRiskAssessment",
                                    "internalEnvironmentConsideration",
                                    "currentEnvironment",
                                    index
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    addNewLine3(
                      "netRiskAssessment",
                      "internalEnvironmentConsideration",
                      "currentEnvironment"
                    );
                  }}
                >
                  Add New Line
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="improvementOpportunities">
                  Improvement Opportunities
                </label>
                <table className="hoverTable">
                  <tbody>
                    {props.riskStore.riskDetail.netRiskAssessment.internalEnvironmentConsideration.improvementOpportunities.map(
                      function (item, index) {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                "word-break": "break-word",
                                width: "90%",
                              }}
                            >
                              <ContentEditable
                                html={item.detail}
                                onBlur={(event) =>
                                  editLine3(
                                    event,
                                    "netRiskAssessment",
                                    "internalEnvironmentConsideration",
                                    "improvementOpportunities",
                                    index
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  confirmDeleteLine3(
                                    "netRiskAssessment",
                                    "internalEnvironmentConsideration",
                                    "improvementOpportunities",
                                    index
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    addNewLine3(
                      "netRiskAssessment",
                      "internalEnvironmentConsideration",
                      "improvementOpportunities"
                    );
                  }}
                >
                  Add New Line
                </button>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="controllability">Controllability</label>
                <Select
                  value={
                    props.riskStore.riskDetail.netRiskAssessment.controllability
                  }
                  options={props.riskStore.settings.controllability}
                  onChange={(event) =>
                    changeSelect2(event, "netRiskAssessment", "controllability")
                  }
                />
              </div>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm more-info-button"
                onClick={() => toggleModalOpen("controlAbilityDetail")}
              >
                Guidance
              </button>
              <Modal
                show={controlAbilityDetail}
                onHide={() => toggleModalOpen("controlAbilityDetail")}
              >
                <Modal.Body>
                  <ControllabilityDetails />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-primary"
                    onClick={() => toggleModalOpen("controlAbilityDetail")}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="controlEffectivenessAssessment">
                  Control Effectiveness Assessment
                </label>
                <Select
                  value={
                    props.riskStore.riskDetail.netRiskAssessment
                      .controlEffectivenessAssessment
                  }
                  options={props.riskStore.settings.controlEffectiveness}
                  onChange={(event) =>
                    changeSelect2(
                      event,
                      "netRiskAssessment",
                      "controlEffectivenessAssessment"
                    )
                  }
                />
              </div>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm more-info-button"
                onClick={() => toggleModalOpen("controlEffectivenessDetail")}
              >
                Guidance
              </button>
              <Modal
                show={controlEffectivenessDetail}
                onHide={() => toggleModalOpen("controlEffectivenessDetail")}
              >
                <Modal.Body>
                  <ControlEffectivenessDetails />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      toggleModalOpen("controlEffectivenessDetail")
                    }
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="netConsequence">Net Consequence</label>
                <Select
                  value={
                    props.riskStore.riskDetail.netRiskAssessment.netConsequence
                  }
                  options={props.riskStore.settings.riskConsequences}
                  onChange={(event) =>
                    changeSelect2(event, "netRiskAssessment", "netConsequence")
                  }
                />
              </div>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm more-info-button"
                onClick={() => toggleModalOpen("conseqDetail")}
              >
                Guidance
              </button>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="netLikelihood">Net Likelihood</label>
                <Select
                  value={
                    props.riskStore.riskDetail.netRiskAssessment.netLikelihood
                  }
                  options={props.riskStore.settings.riskLikelihoods}
                  onChange={(event) =>
                    changeSelect2(event, "netRiskAssessment", "netLikelihood")
                  }
                />
              </div>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm more-info-button"
                onClick={() => toggleModalOpen("likelyDetail")}
              >
                Guidance
              </button>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="netRiskRating">Net Risk Rating</label>
              </div>
              <div
                className="ratingBox"
                style={{
                  backgroundColor: `${props.riskStore.riskDetail.netRiskAssessment.netRiskRating.value}`,
                }}
              >
                <h4>
                  {
                    props.riskStore.riskDetail.netRiskAssessment.netRiskRating
                      .label
                  }
                </h4>
              </div>
            </div>
          </div>
        </form>

        <RiskHistory />
        {props.riskStore.riskDetail.archived ? (
            <button
                className="btn btn-outline-danger"
                onClick={() => toggleModalOpen("archiveModal")}
            >
              Unarchive Risk
            </button>
        ) : (
          <button
            className="btn btn-outline-danger"
            onClick={() => toggleModalOpen("archiveModal")}
          >
            Archive Risk
          </button>
        )}
        <Modal
          show={archiveModal}
          onHide={() => toggleModalOpen("archiveModal")}
        >
          <Modal.Body>
            {props.riskStore.riskDetail.archived ? <UnArchiveModal />  : <ArchiveModal />}
          </Modal.Body>
          <Modal.Footer>
            {props.riskStore.archiveCheck.toUpperCase() === "ARCHIVE" ? props.riskStore.riskDetail.archived ?
                (
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                          unArchiveRisk();
                          toggleModalOpen("archiveModal");
                        }}
                    >
                      Un-Archive Risk
                    </button>
                ) :
                    (
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                              archiveRisk();
                              toggleModalOpen("archiveModal");
                            }}
                        >
                          Archive Risk
                        </button>
                    )
                : null}
            <button
              className="btn btn-primary"
              onClick={() => {
                toggleModalOpen("archiveModal");
                clearComment();
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <br />
        <br />
      </div>
    </div>
  );
}

export default inject("riskStore")(observer(RiskDetail));
