import React from "react";
import { inject, observer } from "mobx-react";

function PriorityDetails(props) {
  return (
    <div>
      <h1>Assessing the Issue Priority</h1>
      <p>
        Please use the following criteria to assess the Issue Priority.
      </p>
      <table className="table table-striped">
        <thead>
          <tr className="bg-primary instructHead">
            <th style={{ width: "40%" }}>Priority</th>
            <th style={{ width: "60%" }}>Description</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.Priorities.map(function (item, index) {
            return (
              <tr key={index}>
                <td className="instructCell">{item.label}</td>
                <td>{item.description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default inject("riskStore")(observer(PriorityDetails));
