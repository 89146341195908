import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Axios from "axios";
import Spinner from "../../layout/Spinner";
import Originators from "./Originators";
import LessonArea from "./lessonArea";
import LessonRating from "./lessonRating";

function LessonTabs(props) {
  const { getTokenSilently, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(false);

    useEffect(() => {
        updateData();
    }, []);

    const updateData = async () => {
        const token = await getTokenSilently();

        Axios({
            method: "get",
            url: "/api/settings/getall",
            headers: {
                accepts: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((settings) => {
                console.log(settings.data.lessonRating)
                props.riskStore.settings = settings.data;
                setDataLoaded(true);
            })
            .catch((error) => console.log(error));
    };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
      <div>
        <h1>Lesson Settings</h1>
        <p>
          Using the tabs below you can update the lesson settings for the entire
          app.
        </p>
        <Tabs>
          <TabList>
            <Tab>Originators </Tab>
            <Tab>Areas </Tab>
              <Tab>Ratings </Tab>
          </TabList>
          <TabPanel>
            <Originators />
          </TabPanel>
            <TabPanel>
                <LessonArea />
          </TabPanel>
            <TabPanel>
                <LessonRating />
            </TabPanel>
        </Tabs>
      </div>
  );
}

export default inject("riskStore")(observer(LessonTabs));
