import React, { useState } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import Axios from "axios";
import Select from "react-select";

function Roles(props) {
  const { getTokenSilently } = useAuth0();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [id, setId] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [internal, setInternal] = useState(true);

  const saveChanges = async () => {
    setLoading(true);
    const token = await getTokenSilently();
    if (index === "new") {
      const data = {
        type: "role",
        label,
        value,
        internal,
      };
      Axios({
        method: "post",
        url: "/api/settings/add",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          const settingData = setting.data;
          let newSetting = { ...settingData };
          newSetting.id = newSetting._id;
          props.riskStore.addNewSetting("roles", newSetting);
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      const data = {
        _id: id,
        id,
        label,
        value,
        internal,
      };
      Axios({
        method: "post",
        url: "/api/settings/modify",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          props.riskStore.settings.roles[index] = data;
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const toggleModalOpen = () => {
    setShowModal(!showModal);
    setId("");
    setLabel("");
    setValue("");
    setInternal(true);
    setChanged(false);
  };

  const newRole = () => {
    setIndex("new");
    setId("");
    setLabel("");
    setValue("");
    setInternal(true);
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const editRow = (index) => {
    setIndex(index);
    setId(props.riskStore.settings.roles[index].id);
    setLabel(props.riskStore.settings.roles[index].label);
    setValue(props.riskStore.settings.roles[index].value);
    setInternal(props.riskStore.settings.roles[index].internal);
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const deleteCategory = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this role? This is irreversible."
      )
    ) {
      setLoading(true);
      const token = await getTokenSilently();
      const data = {
        _id: id,
      };
      Axios({
        method: "delete",
        url: "/api/settings/delete",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          props.riskStore.deleteSetting("roles", index);
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const checkChange = () => {
    if (!changed) {
      setChanged(true);
    }
  };

  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "catName":
        setLabel(value);
        setValue(value);
        break;
      default:
        break;
    }
    checkChange();
  };

  const changeSelect = (event, key) => {
    const value = event.value;
    switch (key) {
      case "internal":
        setInternal(value);
        break;
      default:
        break;
    }
    checkChange();
  };

  return (
    <div>
      <Modal show={showModal} onHide={() => toggleModalOpen()}>
        <Modal.Header>
          <h3>
            {index === "new"
              ? "Create New Role (all fields required)"
              : "Edit Role - " + label}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="catName">Name</label>
                <input
                  type="text"
                  id="catName"
                  name="catName"
                  value={label}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <br />
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="internal">Internal Role?</label>
                <Select
                  value={
                    internal === true
                      ? "MPDC Staff/Stakeholder"
                      : "External (Contractor/Consultant)"
                  }
                  placeholder={
                    internal === true
                      ? "MPDC Staff/Stakeholder"
                      : "External (Contractor/Consultant)"
                  }
                  options={[
                    { value: true, label: "MPDC Staff/Stakeholder" },
                    { value: false, label: "External (Contractor/Consultant)" },
                  ]}
                  onChange={(event) => changeSelect(event, "internal")}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {loading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    deleteCategory();
                  }}
                >
                  Delete Role
                </button>
                {changed ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      saveChanges();
                    }}
                  >
                    Save
                  </button>
                ) : null}
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-sm-10">
          <h1>Roles</h1>
          <p>
            Roles refer to the responsible officer, primary & secondary, for a
            risk. Using the table below you are able to update the roles in the
            system, and mark them as internal or external to the organisation.
          </p>
        </div>
        <div className="col-sm-2">
          <button
            style={{ float: "right" }}
            className="btn btn-primary"
            onClick={() => {
              newRole();
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Role
          </button>
        </div>
      </div>

      <br />

      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th style={{ width: "70%" }}>Name</th>
            <th style={{ width: "30%" }}>Internal?</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.roles.map(function (role, index) {
            return (
              <tr key={index} onDoubleClick={() => editRow(index)}>
                <td>{role.label}</td>
                <td>
                  {role.internal === true
                    ? "MPDC Staff/Stakeholder"
                    : "External (Contractor/Consultant)"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default inject("riskStore")(observer(Roles));
