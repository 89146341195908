import React, { useState } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import Axios from "axios";

function Likelihoods(props) {
  const { getTokenSilently } = useAuth0();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [id, setId] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  const [occurence, setOccurence] = useState("");
  const [percentage, setPercentage] = useState("");

  const saveChanges = async () => {
    setLoading(true);
    const token = await getTokenSilently();
    const data = {
      _id: id,
      label,
      value,
      id,
      description,
      occurence,
      percentage,
    };
    Axios({
      method: "post",
      url: "/api/settings/modify",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((setting) => {
        props.riskStore.settings.riskLikelihoods[index] = data;
        toggleModalOpen();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const toggleModalOpen = () => {
    setShowModal(!showModal);
    setId("");
    setLabel("");
    setValue("");
    setDescription("");
    setOccurence("");
    setPercentage("");
    setChanged(false);
  };

  const editRow = (index) => {
    setIndex(index);
    setId(props.riskStore.settings.riskLikelihoods[index].id);
    setLabel(props.riskStore.settings.riskLikelihoods[index].label);
    setValue(props.riskStore.settings.riskLikelihoods[index].value);
    setDescription(props.riskStore.settings.riskLikelihoods[index].description);
    setOccurence(props.riskStore.settings.riskLikelihoods[index].occurence);
    setPercentage(props.riskStore.settings.riskLikelihoods[index].percentage);
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "description":
        setDescription(value);
        break;
      case "occurence":
        setOccurence(value);
        break;
      case "percentage":
        setPercentage(value);
        break;
      default:
        break;
    }
    if (!changed) {
      setChanged(true);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={() => toggleModalOpen()}>
        <Modal.Header>
          <h3>{"Edit Likelihood - " + label}</h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="catName">Likelihood Name</label>
                <input
                  type="text"
                  id="catName"
                  name="catName"
                  value={label}
                  disabled={true}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="percentage">Likely Percent</label>
                <input
                  type="text"
                  id="percentage"
                  name="percentage"
                  value={percentage}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="2"
                  value={description}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="occurence">Occurence</label>
                <textarea
                  name="occurence"
                  id="occurence"
                  cols="30"
                  rows="2"
                  value={occurence}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {loading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                {changed ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      saveChanges();
                    }}
                  >
                    Save
                  </button>
                ) : null}
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-sm-10">
          <h1>Risk Likelihood</h1>
          <p>
            Using the table below you are able to update the likelihood
            criteria. The descriptors in the table below are those that will be
            shown to staff to guide them when choosing a risk likelihood (in the
            risk detail screen).
          </p>
        </div>
      </div>

      <br />
      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th style={{ width: "24%" }}>Name</th>
            <th style={{ width: "10%" }}>Likely Percent</th>
            <th style={{ width: "33%" }}>Description</th>
            <th style={{ width: "33%" }}>Occurence</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.riskLikelihoods.map(function (
            likelihood,
            index
          ) {
            return (
              <tr key={index} onDoubleClick={() => editRow(index)}>
                <td>{likelihood.label}</td>
                <td>{likelihood.percentage}</td>
                <td>{likelihood.description}</td>
                <td>{likelihood.occurence}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="row">
        <div className="col-sm-10">
          <p>
            <i>
              Please note: The name of likelihood is not editable due to logic
              involved in the matrix. If this needs to be changed, or you need
              to add a new likelihood, then please lodge a change request with{" "}
              <a href="mailto: phoenix@risen.solutions?subject=Change Request - MPDC Risk">
                Risen Solutions Apps
              </a>
              .
            </i>
          </p>
        </div>
      </div>
      <br />
    </div>
  );
}

export default inject("riskStore")(observer(Likelihoods));
