import React, { useState } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import Axios from "axios";

function LessonArea(props) {
  const { getTokenSilently } = useAuth0();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [id, setId] = useState("");
  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");

  const saveChanges = async () => {
    if(label === "" || label === null || label === undefined) {
     toast.error("Name is required")
    } else {
      setLoading(true);
      const token = await getTokenSilently();
      if (index === "new") {
        const data = {
          type: "lessonArea",
          label,
          description,
        };
        Axios({
          method: "post",
          url: "/api/settings/add",
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        })
            .then((setting) => {
              const settingData = setting.data;
              let newSetting = { ...settingData };
              console.log(newSetting);
              newSetting.id = newSetting._id;
              props.riskStore.addNewSetting("lessonArea", newSetting);
              toggleModalOpen();
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
      } else {
        const data = {
          _id: id,
          id,
          label,
          description,
        };
        Axios({
          method: "post",
          url: "/api/settings/modify",
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        })
            .then((setting) => {
              props.riskStore.settings.lessonArea[index] = data;
              toggleModalOpen();
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
      }
    }
  };

  const toggleModalOpen = () => {
    setShowModal(!showModal);
    setId("");
    setLabel("");
    setDescription("");
    setChanged(false);
  };

  const newArea = () => {
    setIndex("new");
    setId("");
    setLabel("");
    setDescription("");
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const editRow = (index) => {
    setIndex(index);
    setId(props.riskStore.settings.lessonArea[index].id);
    setLabel(props.riskStore.settings.lessonArea[index].label);
    setDescription(
      props.riskStore.settings.lessonArea[index].description
    );
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const deleteArea = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this area? This is irreversible."
      )
    ) {
      setLoading(true);
      const token = await getTokenSilently();
      const data = {
        _id: id,
      };
      Axios({
        method: "delete",
        url: "/api/settings/delete",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          props.riskStore.deleteSetting("lessonArea", index);
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "catName":
        setLabel(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
    if (!changed) {
      setChanged(true);
    }
  };

  const changeSelect = (event, key) => {
    const value = event.label;
    console.log(key);
    switch (key) {
      case "broadCategory":
        console.log(value);
        break;
      default:
        break;
    }
    if (!changed) {
      setChanged(true);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={() => toggleModalOpen()}>
        <Modal.Header>
          <h3>
            {index === "new"
              ? "Create New Area (name is required)"
              : "Edit Area - " + label}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="catName">Area Name</label>
                <input
                  type="text"
                  id="catName"
                  name="catName"
                  value={label}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="2"
                  value={description}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {loading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                {
index !== 'new' ? <button
    className="btn btn-sm btn-danger"
    onClick={() => {
      deleteArea();
    }}
>
  Delete Area
</button> : null
                }
                {changed ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      saveChanges();
                    }}
                  >
                    Save
                  </button>
                ) : null}
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-sm-10">
          <h1>Areas</h1>
          <p>
            Used to delineate a sub-area within a project.
          </p>
        </div>
        <div className="col-sm-2">
          <button
            style={{ float: "right" }}
            className="btn btn-primary"
            onClick={() => {
              newArea();
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Area
          </button>
        </div>
      </div>
      <br />
      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th style={{ width: "25%" }}>Name</th>
            <th style={{ width: "45%" }}>Description</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.lessonArea.map(function (
            category,
            index
          ) {
            return (
              <tr key={index} onDoubleClick={() => editRow(index)}>
                <td>{category.label}</td>
                <td>{category.description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </div>
  );
}

export default inject("riskStore")(observer(LessonArea));
