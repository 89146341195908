import React from "react";
import { inject, observer } from "mobx-react";

function ConseqDetails(props) {
  return (
    <div>
      <h1>Assessing the Consequence Ranking</h1>
      <p>
        Risks are assessed in terms of the consequence of their impact on
        strategic objectives. The following table is used to guide the
        assessment of consequences of each identified risk:
      </p>
      <table className="table table-striped">
        <thead>
          <tr className="bg-primary instructHead">
            <th colSpan="7">Consequence Category</th>
          </tr>
          <tr className="bg-primary instructHead">
            <th style={{ width: "10%" }}>Name</th>
            <th style={{ width: "15%" }}>Description</th>
            <th style={{ width: "15%" }}>Insignificant</th>
            <th style={{ width: "15%" }}>Minor</th>
            <th style={{ width: "15%" }}>Moderate</th>
            <th style={{ width: "15%" }}>Major</th>
            <th style={{ width: "15%" }}>Catastrophic</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.broadRiskCategories.map(function (
            item,
            index
          ) {
            return (
              <tr key={index}>
                <td className="instructCell">{item.label}</td>
                <td>{item.description}</td>
                <td>{item.insignificant}</td>
                <td>{item.minor}</td>
                <td>{item.moderate}</td>
                <td>{item.major}</td>
                <td>{item.catastrophic}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default inject("riskStore")(observer(ConseqDetails));
