import React, { useState, useEffect, Fragment } from "react";
import { useAuth0 } from "../../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import Axios from "axios";
import Spinner from "../../layout/Spinner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import ProjectRiskReview from "./ProjectRiskReview";
import RiskManagementReview from "./RiskManagementReview";
import RiskRatings from "./RiskRatings";
import RiskDetail from "./RiskDetail";

import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";

function RiskTabs(props) {
  const { getTokenSilently, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(false);

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const token = await getTokenSilently();

    Axios({
      method: "get",
      url: "/api/settings/getall",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((settings) => {
        props.riskStore.settings = settings.data;
        setDataLoaded(true);
      })
      .catch((error) => console.log(error));
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
    <div>
      <h2 style={{float: "right"}}>Risk Reports</h2>
      <Tabs defaultIndex={0}>
        <TabList style={{backgroundColor:" #a0d8ef"}}>
          <Tab>Risk Detail</Tab>
          <Tab>Project Risk Review</Tab>
          <Tab>Risk Ratings</Tab>
          <Tab>Risk Management Review</Tab>
        </TabList>
        <TabPanel>
          <RiskDetail />
        </TabPanel>
        <TabPanel>
          <ProjectRiskReview />
        </TabPanel>
        <TabPanel>
          <RiskRatings />
        </TabPanel>
        <TabPanel>
          <RiskManagementReview />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default inject("riskStore")(observer(RiskTabs));
