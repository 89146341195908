import React from "react";
import { inject, observer } from "mobx-react";

function PostModal(props) {
  const handleComment = (event) => {
    const text = event.target.value;
    props.riskStore.handleUpdateComment(text);
  };

  return (
    <div>
      <h1>Are you sure?</h1>
      <p>
        Please enter any comments you would like to record against the changes
        you have made. Once you are done, click 'Update Risk' below.
      </p>
      <div className="row">
        <div className="col-md-12">
          <textarea
            className="form-control form-control-large mb-3"
            name="updateComment"
            id="updateComment"
            rows="5"
            value={props.riskStore.updateComment}
            onChange={handleComment}
          />
        </div>
      </div>
    </div>
  );
}

export default inject("riskStore")(observer(PostModal));
