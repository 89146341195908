import React, { useState } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import Axios from "axios";

function Controllability(props) {
  const { getTokenSilently } = useAuth0();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [id, setId] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");

  const saveChanges = async () => {
    setLoading(true);
    const token = await getTokenSilently();
    if (index === "new") {
      const data = {
        type: "controllability",
        label,
        value,
        description,
      };
      Axios({
        method: "post",
        url: "/api/settings/add",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          const settingData = setting.data;
          let newSetting = { ...settingData };
          newSetting.id = newSetting._id;
          props.riskStore.addNewSetting("controllability", newSetting);
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      const data = {
        _id: id,
        id,
        label,
        value,
        description,
      };
      Axios({
        method: "post",
        url: "/api/settings/modify",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          props.riskStore.settings.controllability[index] = data;
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const toggleModalOpen = () => {
    setShowModal(!showModal);
    setId("");
    setLabel("");
    setValue("");
    setDescription("");
    setChanged(false);
  };

  const newControllability = () => {
    setIndex("new");
    setId("");
    setLabel("");
    setValue("");
    setDescription("");
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const editRow = (index) => {
    setIndex(index);
    setId(props.riskStore.settings.controllability[index].id);
    setLabel(props.riskStore.settings.controllability[index].label);
    setValue(props.riskStore.settings.controllability[index].value);
    setDescription(props.riskStore.settings.controllability[index].description);
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const deleteCategory = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this category? This is irreversible."
      )
    ) {
      setLoading(true);
      const token = await getTokenSilently();
      const data = {
        _id: id,
      };
      Axios({
        method: "delete",
        url: "/api/settings/delete",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          props.riskStore.deleteSetting("controllability", index);
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "catName":
        setLabel(value);
        setValue(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
    if (!changed) {
      setChanged(true);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={() => toggleModalOpen()}>
        <Modal.Header>
          <h3>
            {index === "new"
              ? "Create New Controllability Field (all fields required)"
              : "Edit Controllability Field - " + label}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="catName">Name</label>
                <input
                  type="text"
                  id="catName"
                  name="catName"
                  value={label}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="2"
                  value={description}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {loading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    deleteCategory();
                  }}
                >
                  Delete Category
                </button>
                {changed ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      saveChanges();
                    }}
                  >
                    Save
                  </button>
                ) : null}
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-sm-10">
          <h1>Risk Controllability</h1>
          <p>
            Using the table below you are able to update the controllability
            criteria. The descriptors in the table below are those that will be
            shown to staff to guide them when choosing a risk controllability
            (in the risk detail screen).
          </p>
        </div>
        <div className="col-sm-2">
          <button
            style={{ float: "right" }}
            className="btn btn-primary"
            onClick={() => {
              newControllability();
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Controllability Field
          </button>
        </div>
      </div>

      <br />

      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th style={{ width: "30%" }}>Name</th>
            <th style={{ width: "70%" }}>Description</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.controllability.map(function (
            category,
            index
          ) {
            return (
              <tr key={index} onDoubleClick={() => editRow(index)}>
                <td>{category.label}</td>
                <td>{category.description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default inject("riskStore")(observer(Controllability));
