import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "../auth/react-auth0-spa";

function Navbar(props) {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const mainContent = isAuthenticated ? (
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="nav-link btn" to="/risk-register">
            Risk Register
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link btn" to="/issues-register">
            Issues Register
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link btn" to="/lessons-register">
            Lessons Register
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link btn" to="/reports">
            Reports
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link btn" to="/settings">
            Settings
          </Link>
        </li>
        <li>
          <button onClick={() => logout()} className="nav-link btn">
            Logout
          </button>
        </li>
      </ul>
    </div>
  ) : (
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ml-auto">
        <li>
          <button onClick={() => loginWithRedirect()} className="nav-link btn">
            Login
          </button>
        </li>
      </ul>
    </div>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <div className="container">
        <Link className="navbar-brand" to="/">
          MPDC - Risk Management System
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
        >
          <span className="navbar-toggler-icon" />
        </button>
        {mainContent}
      </div>
    </nav>
  );
}

export default Navbar;
