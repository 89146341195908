import React from "react";
import { inject, observer } from "mobx-react";

function LikelyDetails(props) {
  return (
    <div>
      <h1>Assessing the Likelihood Ranking</h1>
      <p>
        The likelihood that the business will be exposed to each specific risk
        is determined considering factors such as:
      </p>
      <ul>
        <li>
          History of previous events and factors which may impact that looking
          forward.
        </li>
        <li>The current internal and external environment.</li>
      </ul>
      <p>
        For the purposes of assessing likelihood the following scale will be
        used:
      </p>
      <table className="table table-striped">
        <thead>
          <tr className="bg-primary instructHead">
            <th style={{ width: "10%" }}>Likelihood</th>
            <th style={{ width: "40%" }}>Change</th>
            <th style={{ width: "5%" }}>Probability</th>
            <th style={{ width: "45%" }}>Frequency</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.riskLikelihoods.map(function (item, index) {
            return (
              <tr key={index}>
                <td className="instructCell">{item.label}</td>
                <td>{item.description}</td>
                <td>{item.percentage}</td>
                <td>{item.occurence}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default inject("riskStore")(observer(LikelyDetails));
