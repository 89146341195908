import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import axios from "axios";
import Spinner from "../layout/Spinner";
import moment from "moment-timezone";
import underScore from "underscore";

function IssueHistoryDetail(props) {
  const [dataLoaded, setdataLoaded] = useState(false);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    getIssueHistory();
  }, []);

  const getIssueHistory = async () => {
    const token = await getTokenSilently();
    axios({
      method: "get",
      url: `/api/issue/issue-history?id=${props.match.params.issueSnapID}`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((snapshot) => {
        props.issuesStore.updateSnapshot(snapshot.data);
        console.log(snapshot.data)
        console.log("Snapshot successfully loaded.");
        setdataLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div id="riskDetailForm">
      {dataLoaded ? (
        <div>
          <h1>Issue Change History</h1>
          <br />
          <div className="row">
            <div className="col-md-4">
              <table className="hoverTable">
                <tbody>
                  <tr>
                    <td>Change Commit ID:</td>
                    <td>{props.issuesStore.snapshot._id}</td>
                  </tr>
                  <tr>
                    <td>Change User: </td>
                    <td>{props.issuesStore.snapshot.changeUser.userName}</td>
                  </tr>
                  <tr>
                    <td>User Email: </td>
                    <td>{props.issuesStore.snapshot.changeUser.userEmail}</td>
                  </tr>
                  <tr>
                    <td>Date of Change: </td>
                    <td>
                      {moment(props.issuesStore.snapshot.date)
                        .tz("Australia/Hobart")
                        .format("DD MMMM YYYY h:mm a")}
                    </td>
                  </tr>
                  <tr>
                    <td>Today's Date: </td>
                    <td>
                      {moment()
                        .tz("Australia/Hobart")
                        .format("DD MMMM YYYY h:mm a")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-4">
              <table className="hoverTable">
                <tbody>
                  <tr>
                    <td>User Comments: </td>
                    <td>{props.issuesStore.snapshot.comment}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <div className="btn-group-vertical">
                <button
                  className="btn btn-primary"
                  onClick={() => window.print()}
                >
                  Print Report
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => window.history.back()}
                >
                  Back to Issue
                </button>
              </div>
            </div>
          </div>
          <br />
          <div className="row"></div>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th colSpan="4">Changed Parameters</th>
                  </tr>
                  <tr>
                    <th style={{ width: "16%" }}>Parameter</th>
                    <th style={{ width: "14%" }}>Group</th>
                    <th style={{ width: "35%" }}>Before Change</th>
                    <th style={{ width: "35%" }}>After Change</th>
                  </tr>
                </thead>
                <tbody>
                  {props.issuesStore.snapshot.newIssue.resolved === true ? (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <h1>Resolved Issue</h1>
                      </td>
                    </tr>
                  ) : null}
                  {underScore.isEqual(
                    props.issuesStore.snapshot.oldIssue.project.value,
                    props.issuesStore.snapshot.newIssue.project.value
                  ) ? null : (
                    <tr>
                      <td>Project</td>
                      <td>Header</td>
                      <td>{props.issuesStore.snapshot.oldIssue.project.value}</td>
                      <td>{props.issuesStore.snapshot.newIssue.project.value}</td>
                    </tr>
                  )}
                  {underScore.isEqual(
                      props.issuesStore.snapshot.oldIssue.classification.value,
                      props.issuesStore.snapshot.newIssue.classification.value
                  ) ? null : (
                      <tr>
                        <td>Classification</td>
                        <td>Header</td>
                        <td>{props.issuesStore.snapshot.oldIssue.classification.value}</td>
                        <td>{props.issuesStore.snapshot.newIssue.classification.value}</td>
                      </tr>
                  )}
                  {underScore.isEqual(
                      props.issuesStore.snapshot.oldIssue.responsibleOfficer.value,
                      props.issuesStore.snapshot.newIssue.responsibleOfficer.value
                  ) ? null : (
                      <tr>
                        <td>Responsible Officer</td>
                        <td>Header</td>
                        <td>
                          {
                            props.issuesStore.snapshot.oldIssue.responsibleOfficer.value
                          }
                        </td>
                        <td>
                          {
                            props.issuesStore.snapshot.newIssue.responsibleOfficer.value
                          }
                        </td>
                      </tr>
                  )}
                  {underScore.isEqual(
                    props.issuesStore.snapshot.oldIssue.originator.value,
                    props.issuesStore.snapshot.newIssue.originator.value
                  ) ? null : (
                    <tr>
                      <td>Raised By</td>
                      <td>Header</td>
                      <td>
                        {
                          props.issuesStore.snapshot.oldIssue.originator.value
                        }
                      </td>
                      <td>
                        {
                          props.issuesStore.snapshot.newIssue.originator.value
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.issuesStore.snapshot.oldIssue.dateRaised,
                    props.issuesStore.snapshot.newIssue.dateRaised
                  ) ? null : (
                    <tr>
                      <td>Date Raised</td>
                      <td>Header</td>
                      <td>
                        {
                          moment(props.issuesStore.snapshot.oldIssue.dateRaised).format("YYYY-MM-DD")
                        }
                      </td>
                      <td>
                        {
                          moment(props.issuesStore.snapshot.newIssue.dateRaised).format("YYYY-MM-DD")
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                      props.issuesStore.snapshot.oldIssue.priority.label,
                      props.issuesStore.snapshot.newIssue.priority.label
                  ) ? null : (
                      <tr>
                        <td>Priority</td>
                        <td>Header</td>
                        <td>
                          {
                            props.issuesStore.snapshot.oldIssue.priority.label
                          }
                        </td>
                        <td>
                          {
                            props.issuesStore.snapshot.newIssue.priority.label
                          }
                        </td>
                      </tr>
                  )}
                  {underScore.isEqual(
                      props.issuesStore.snapshot.oldIssue.severity.label,
                      props.issuesStore.snapshot.newIssue.severity.label
                  ) ? null : (
                      <tr>
                        <td>Severity</td>
                        <td>Header</td>
                        <td>
                          {
                            props.issuesStore.snapshot.oldIssue.severity.label
                          }
                        </td>
                        <td>
                          {
                            props.issuesStore.snapshot.newIssue.severity.label
                          }
                        </td>
                      </tr>
                  )}
                  {underScore.isEqual(
                    props.issuesStore.snapshot.oldIssue.comments,
                    props.issuesStore.snapshot.newIssue.comments
                  ) ? null : (
                    <tr>
                      <td>Comments</td>
                      <td>Detail</td>
                      <td>
                        {
                          props.issuesStore.snapshot.oldIssue.comments
                        }
                      </td>
                      <td>
                        {
                          props.issuesStore.snapshot.newIssue.comments
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                      props.issuesStore.snapshot.oldIssue.description,
                      props.issuesStore.snapshot.newIssue.description
                  ) ? null : (
                      <tr>
                        <td>Description</td>
                        <td>Detail</td>
                        <td>
                          {
                            props.issuesStore.snapshot.oldIssue.description
                          }
                        </td>
                        <td>
                          {
                            props.issuesStore.snapshot.newIssue.description
                          }
                        </td>
                      </tr>
                  )}

                </tbody>
              </table>
            </div>
            <div className="col-md-12"></div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default inject("issuesStore")(observer(IssueHistoryDetail));
