import React, { useState } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import Axios from "axios";

function NarrowCategories(props) {
  const { getTokenSilently } = useAuth0();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [id, setId] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  const [broadCategory, setBroadCategory] = useState("");

  const saveChanges = async () => {
    setLoading(true);
    const token = await getTokenSilently();
    if (index === "new") {
      const data = {
        type: "narrowRiskCategories",
        label,
        value,
        description,
        broadCategory,
      };
      Axios({
        method: "post",
        url: "/api/settings/add",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          const settingData = setting.data;
          let newSetting = { ...settingData };
          console.log(newSetting);
          newSetting.id = newSetting._id;
          props.riskStore.addNewSetting("narrowRiskCategories", newSetting);
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      const data = {
        _id: id,
        id,
        label,
        value,
        description,
        broadCategory,
      };
      Axios({
        method: "post",
        url: "/api/settings/modify",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          props.riskStore.settings.narrowRiskCategories[index] = data;
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const toggleModalOpen = () => {
    setShowModal(!showModal);
    setId("");
    setLabel("");
    setValue("");
    setDescription("");
    setBroadCategory("");
    setChanged(false);
  };

  const newCategory = () => {
    setIndex("new");
    setId("");
    setLabel("");
    setValue("");
    setDescription("");
    setBroadCategory("");
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const editRow = (index) => {
    setIndex(index);
    setId(props.riskStore.settings.narrowRiskCategories[index].id);
    setLabel(props.riskStore.settings.narrowRiskCategories[index].label);
    setValue(props.riskStore.settings.narrowRiskCategories[index].value);
    setDescription(
      props.riskStore.settings.narrowRiskCategories[index].description
    );
    setBroadCategory(
      props.riskStore.settings.narrowRiskCategories[index].broadCategory
    );
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const deleteCategory = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this category? This is irreversible."
      )
    ) {
      setLoading(true);
      const token = await getTokenSilently();
      const data = {
        _id: id,
      };
      Axios({
        method: "delete",
        url: "/api/settings/delete",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          props.riskStore.deleteSetting("narrowRiskCategories", index);
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "catName":
        setLabel(value);
        setValue(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
    if (!changed) {
      setChanged(true);
    }
  };

  const changeSelect = (event, key) => {
    const value = event.label;
    console.log(key);
    switch (key) {
      case "broadCategory":
        console.log(value);
        setBroadCategory(value);
        break;
      default:
        break;
    }
    if (!changed) {
      setChanged(true);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={() => toggleModalOpen()}>
        <Modal.Header>
          <h3>
            {index === "new"
              ? "Create New Category (all fields required)"
              : "Edit Category - " + label}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="catName">Category Name</label>
                <input
                  type="text"
                  id="catName"
                  name="catName"
                  value={label}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="2"
                  value={description}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="broadCategory">Linked Broad Category</label>
                <Select
                  value={broadCategory}
                  placeholder={broadCategory}
                  options={props.riskStore.settings.broadRiskCategories}
                  onChange={(event) => changeSelect(event, "broadCategory")}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {loading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    deleteCategory();
                  }}
                >
                  Delete Category
                </button>
                {changed ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      saveChanges();
                    }}
                  >
                    Save
                  </button>
                ) : null}
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-sm-10">
          <h1>Narrow Categories</h1>
          <p>
            Narrow categories allow for finer data segmentation. This is the
            ideal place to record individual projects, assets (eg, Red Shed), or
            other more detailed risks.
          </p>
          <p>
            These risks can be grouped to broad categories so that, after
            choosing a broad category, only the relevant narrow categories will
            appear in the risk detail screen.
          </p>
        </div>
        <div className="col-sm-2">
          <button
            style={{ float: "right" }}
            className="btn btn-primary"
            onClick={() => {
              newCategory();
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Category
          </button>
        </div>
      </div>
      <br />
      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th style={{ width: "25%" }}>Name</th>
            <th style={{ width: "45%" }}>Description</th>
            <th style={{ width: "30%" }}>Linked Broad Category</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.narrowRiskCategories.map(function (
            category,
            index
          ) {
            return (
              <tr key={index} onDoubleClick={() => editRow(index)}>
                <td>{category.label}</td>
                <td>{category.description}</td>
                <td>{category.broadCategory}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </div>
  );
}

export default inject("riskStore")(observer(NarrowCategories));
