import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Spinner from "../../layout/Spinner";
import Originators from "./Originators";
import Priorities from "./Priorities";
import Severities from "./Severities";
import Axios from "axios";

function IssueTabs(props) {
  const { getTokenSilently, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(false);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    const token = await getTokenSilently();

      Axios({
          method: "get",
          url: "/api/settings/getall",
          headers: {
              accepts: "application/json",
              Authorization: `Bearer ${token}`,
          },
      })
        .then((settings) => {
          props.riskStore.settings = settings.data;
          setDataLoaded(true);
        })
        .catch((error) => console.log(error));
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
      <div>
        <h1>Issue Settings</h1>
        <p>
          Using the tabs below you can update the issue settings for the entire
          app.
        </p>
        <Tabs>
          <TabList>
            <Tab>Originators </Tab>
              <Tab>Priority</Tab>
              <Tab>Severity</Tab>
          </TabList>
          <TabPanel>
            <Originators />
          </TabPanel>
            <TabPanel>
                <Priorities />
            </TabPanel>
            <TabPanel>
                <Severities />
            </TabPanel>
        </Tabs>
      </div>
  );
}

export default inject("riskStore")(observer(IssueTabs));
